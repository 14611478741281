.profile-container {
  margin: 20px;
}

.profile-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.card {
  padding: 20px;
}

.form-container {
  background-color: white;
  padding: 20px;
}

.card {
  //   border: 1px solid #ccc;
  border-radius: 4px;
  padding: 20px;
  margin-bottom: 20px;
}

.profile-form {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.profile-form label {
  margin-right: 10px;
}

.profile-form input {
  flex: 1;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
}
