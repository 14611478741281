@import "../../styles/variables.scss";

.pagination-row {
  display: flex;
  justify-content: space-between;
}
.page-number {
  height: 32px;
  margin-top: 15px;
  display: flex;
  align-items: center;
  padding: 0px 20px;
  border-radius: 5px;
  color: #626262;
  font-size: 14px;
  font-weight: 600;
  background-color: $white;
}
.pagination-container {
  display: flex;
  list-style-type: none;
  padding-left: 0;
  .pagination-item {
    padding: 0 12px;
    height: 32px;
    text-align: center;
    color: rgba(0, 0, 0, 0.87);
    display: flex;
    box-sizing: border-box;
    align-items: center;
    letter-spacing: 0.01071em;
    border: 1px solid #ebebeb;
    font-family: $font-family;
    background: $white;
    line-height: 1.43;
    font-size: 13px;
    min-width: 32px;

    &.dots:hover {
      background-color: transparent;
      cursor: default;
    }
    &:hover {
      background-color: rgba(0, 0, 0, 0.04);
      cursor: pointer;
    }

    &.selected {
      background-color: rgba(0, 0, 0, 0.08);
    }
    &.first {
      border-radius: 5px 0px 0px 5px;
      margin-left: 5px;
    }

    &.last {
      border-radius: 0px 5px 5px 0px;
      margin-right: 5px;
    }
    &.arrow {
      border-radius: 5px;
    }

    .arrow {
      &::before {
        position: relative;
        /* top: 3pt; Uncomment this to lower the icons as requested in comments*/
        content: "";
        /* By using an em scale, the arrows will size with the font */
        display: inline-block;
        width: 0.4em;
        height: 0.4em;
        border-right: 0.12em solid rgba(0, 0, 0, 0.87);
        border-top: 0.12em solid rgba(0, 0, 0, 0.87);
      }

      &.left {
        transform: rotate(-135deg) translate(-50%);
      }

      &.right {
        transform: rotate(45deg);
      }
    }

    &.disabled {
      pointer-events: none;

      .arrow::before {
        border-right: 0.12em solid rgba(0, 0, 0, 0.43);
        border-top: 0.12em solid rgba(0, 0, 0, 0.43);
      }

      &:hover {
        background-color: transparent;
        cursor: default;
      }
    }
  }
}
