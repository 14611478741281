.MoreContainer {
  font-family: "Poppins";
  font-style: normal;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  .moretrigger {
    background: #ffffff;
    border: 1.5px solid #109cf1;
    border-radius: 5px;
    color: #109cf1;
    height: 40px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 50px;
    height: 40px;
    padding: auto;
  }

  span {
    font-weight: 600;
    vertical-align: middle;
    font-size: 13px;
    line-height: 20px;
    height: 20px;
    left: 15.13%;
    right: 3.36%;
    position: absolute;
    justify-content: space-around;
  }

  .icon {
    margin-top: 15px;
    margin-left: 5px;
  }

  .Options {
    display: flex;
    flex-direction: row;
    padding: 3px 3px;
    cursor: pointer;
    &__text {
      margin-left: 8px;
      margin-top: 4px;
      font-family: "Poppins";
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      letter-spacing: 0.00130037px;

      /* Light Blue */

      color: #109cf1;
    }
  }
  .Options.disabled {
    opacity: 0.5;
    color: #707683;
    cursor: not-allowed;
  }

  .more {
    background: #ffffff;
    border-radius: 8px;
    position: absolute;
    top: 60px;
    right: 0;
    width: 140px;
    // height: 119px;
    height: 45px;
    box-shadow: 0 1px 8px #90a0b7;
    opacity: 0;
    visibility: hidden;
  }

  .more.active {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
  }
  .Options__text.redcol {
    color: red;
  }

  .heading {
    margin: 5%;
    padding-bottom: 5px;
    border-bottom: 0.1px ridge #90a0b7;
  }

  &__content {
    padding-left: 5px;
    margin-bottom: 1px;
    margin-top: 10px;
    font-size: 13px;
    color: #707683;
    font-weight: 500;
  }
}
