.Nutrients {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
}

.Nutrients-main {
  position: fixed;
  background: white;
  width: 28%;
  height: 70%;
  top: 50%;
  left: 50%;
  box-shadow: 0px 0px 50px rgba(255, 255, 255, 0.3);
  border-radius: 10px;
  transform: translate(-50%, -50%);
  &__body {
    padding: 20px 35px;
    margin: 15px;
  }
}

.heading {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  /* identical to box height */
  padding-left: 30px;
  padding-top: 10px;
  padding-right: 10px;
  /* Title */

  color: #202c46;
  &__image {
    cursor: pointer;
    float: right;
    margin-right: 10px;
    margin-top: 5px;
  }
}

.display-block {
  z-index: 1;
  display: block;
}

.display-none {
  display: none;
}

.Nutrientsbody {
  padding: 0px 30px;
  overflow: auto;
  height: 47%;
}
.Nutrientswithoutdata {
  padding-top: 10%;
  padding-left: 30px;
  overflow: auto;
  height: 90%;
}

.LegendList {
  display: flex;
  gap: 5px;
  margin: 0px;
}

.LegendList li {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.BulletLabel {
  display: flex;
  align-items: center;
}

.BulletLabelText {
  font-size: 15px;
}
.recharts-legend-wrapper {
  position: inherit !important;
}

.nutrientBox {
  margin: 0;
}
