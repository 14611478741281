@import "../../../styles/variables.scss";

.AddMealContainer {
  display: flex;
  height: calc(100% - 100px) !important;
  .meal_modal_heading {
    /* padding-top: calc(0.375rem + 1px); */
    /* padding-bottom: calc(0.375rem + 1px); */
    width: 100%;
    flex-basis: 35%;
    font-size: 23px;
    margin-left: 30px;
    /* margin-bottom: 1rem; */
    font-weight: 600;
    line-height: 3.2;
  }
  .AddMeal {
    display: flex;
    flex: 1;
    flex-direction: column;
    &.image {
      width: 140px;
      padding-right: 15px;
      border-radius: 6px;
      img {
        border-radius: 10px;
        height: 250px;
      }
    }

    .mealName {
      font-family: $font-family;
      font-style: normal;
      font-weight: 500;
      font-size: 13.6052px;
      line-height: 20px;
      color: #34465e;
      padding-top: 10px;
    }
    label {
      font-family: $font-family;
      font-style: normal;
      font-weight: 400;
      font-size: 9.35358px;
      line-height: 19px;
      letter-spacing: 0.025em;
      color: #707683;
    }
  }
  .centered-text {
    display: grid;
    align-items: center;
    justify-content: center;
    padding: 0;
    label {
      flex-basis: 50%;
    }
  }
  .mealContainer {
    display: flex;
    overflow-x: hidden;
    flex: 2;
    flex-direction: column;
    background: #f3f6fa;
    border: 1px dashed #c2cfe0;
    border-radius: 6px;
    padding: 10px;
  }
  .nutritionFacts {
    &.hide {
      // display: none;
      visibility: hidden;
      opacity: 0;
      height: 0;

      transition: visibility 0s linear 3s, opacity 0.33s linear;
    }
    .show-more {
      color: #06ab8d !important;
      display: flex;
      justify-content: center;
      align-items: center;
      padding-bottom: 15px;
    }
    visibility: visible;
    opacity: 1;
    height: auto;

    display: flex;
    cursor: pointer;
    align-items: center;
  }
  .additionalUnits {
    padding: 10px 0;
    align-items: flex-end;
    justify-content: space-around;
    input[type="text"] {
      border: none;
      margin-right: 0;
      background-color: white;
    }
    input[type="number"] {
      border: none;
      margin-right: 0;
      height: 30px;
      background-color: white;
    }
    .kg {
      background: white;
    }
    .equal {
      display: flex;
      align-items: center;
      padding: 5px;
      color: #707683;
    }
  }
  .add-inputbox {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-flow: column;
    height: 57px;
    label {
      width: 100px;
      display: flex;
      justify-content: right;
    }
    span {
      display: flex;
      gap: 7px;
      align-items: center;
    }
  }
}
.foodrows {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  padding-bottom: 0px !important;
}
