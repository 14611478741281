.tableDataFormat {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  /* identical to box height */

  letter-spacing: 0.01em;

  /* Table Grey */

  color: #707683;
}

.tableColName {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  /* identical to box height */
  padding: 10px;
  letter-spacing: 0.01em;

  /* Light Blue */

  color: #109cf1;
}

.responsivetable {
  border-collapse: collapse;
  width: 100%;
  // border: 1px solid rgba(194, 207, 224, 0.3);
  // box-shadow: 0px 0px 30px rgb(112 112 112 / 30%);
}

table {
  border-collapse: collapse;
  width: 100%;
  border: 1px solid rgba(194, 207, 224, 0.3);
  box-shadow: 0px 0px 30px rgb(112 112 112 / 30%);
}

.mealsPlannerContainer tr:nth-child(odd) {
  background-color: #f9f9f9;
}

tr {
  text-align: left;
}
.tablehead {
  padding: 10px;
}
