@import "../../../styles/variables.scss";
.hr {
  border: 1px dashed #c2cfe0;
}
.MealPlannerContainer {
  margin-bottom: 50px;
  background: #ffffff;
  /* Shadow */

  border-radius: 12px;
  &__header {
    display: flex;
    flex-direction: row;
    padding: 26px 29px 0px 36px;
    &__left {
      display: flex;
      flex: 1 1;
      flex-direction: row;
    }

    &__text {
      font-family: $semi-bold;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      /* identical to box height, or 138% */

      letter-spacing: 0.025em;
      margin-left: 18px;
      /* Dark Grey */
    }
    &__right {
      display: flex;
      &__left {
        display: flex;
        &__text {
          margin-left: 10px;
          margin-right: 20px;
          font-family: $font-medium;
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 20px;
          /* identical to box height, or 167% */

          letter-spacing: 0.0225em;
        }
      }
      &__right {
        display: flex;
        &__text {
          margin-left: 10px;
          font-family: $font-medium;
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 20px;
          /* identical to box height, or 167% */

          letter-spacing: 0.0225em;
        }
      }
    }
  }
  &__content {
    display: flex;
    gap: 45px;

    &__left {
      margin-top: 30px;
      width: 30%;
      height: 256px;

      margin-left: 32px;

      background: #ffffff;

      border: 1px dashed #c2cfe0;
      box-sizing: border-box;
      border-radius: 10px;
      &__header {
        padding: 4px;
        display: flex;

        border-bottom: 1px dashed #c2cfe0;
        &__leftcontent {
          display: flex;
          flex-basis: 85%;
        }
        &__image {
          flex-basis: 10%;
        }
        &__text {
          margin-top: 10px;
        }
        &__utext {
          font-family: $semi-bold;
          font-style: normal;
          font-weight: 600;
          font-size: 12px;

          letter-spacing: 0.025em;

          color: #707683;
        }
        &__dtext {
          font-family: $semi-bold;
          font-style: normal;
          font-weight: 600;
          font-size: 18px;

          letter-spacing: 0.025em;

          color: #34465e;
        }
        &__right {
          padding-bottom: 25px;
          padding-top: 25px;

          flex-basis: 15%;
        }
      }
    }
    &__middle {
      width: 30%;
      height: 256px;

      margin-top: 30px;

      background: #f3f6fa;

      border: 1px dashed #c2cfe0;
      box-sizing: border-box;
      border-radius: 10px;
      &__header {
        border-bottom: 1px dashed #c2cfe0;
        height: 85px;
        background-color: #ffffff;
        padding: 10px;
        display: flex;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        gap: 15px;
        &__bar {
          width: 74px;
          height: 74px;
        }
        &__textcontainer {
          padding: 10px;
        }
        &__utext {
          font-family: $semi-bold;
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 22px;
          /* identical to box height, or 138% */

          letter-spacing: 0.025em;

          /* Dark Grey */

          color: #34465e;
        }
        &__btext {
          height: 30px;
          font-family: $font-family;
          font-style: normal;
          font-weight: 400;
          font-size: 10px;
          line-height: 15px;

          /* Table Grey */

          color: #707683;
        }
      }

      &__content {
        padding: 20px;
        &__data {
          display: flex;
          margin-bottom: 5px;
          padding-left: 10px;
          padding-right: 10px;
          width: auto;
          height: 28px;
          justify-content: space-between;
          font-family: $font-medium;
          font-style: normal;
          font-weight: 500;
          font-size: 10px;
          line-height: 32px;
          /* or 320% */

          letter-spacing: 0.02em;
          text-transform: capitalize;

          /* Table Grey */

          color: #707683;
          background: #ffffff;
          border-radius: 3px;
        }
        &__ldata {
          display: flex;
        }
        &__ldatar {
          padding-left: 5px;
        }
        &__footer {
          display: flex;
          padding-top: 7px;
          &__text {
            padding-left: 5px;
            font-family: "Poppins";
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 20px;
            cursor: pointer;
            /* identical to box height, or 167% */

            letter-spacing: 0.0225em;

            /* Dark Grey */

            color: #34465e;
          }
        }
      }
    }
    &__right {
      width: 30%;
      height: 256px;
      margin-top: 30px;
      margin-right: 32px;
      background: rgba(247, 255, 250, 0.8);
      border: 1px dashed #c2cfe0;
      box-sizing: border-box;
      border-radius: 10px;
      cursor: pointer;
      &__header {
        display: flex;
        padding: 20px 15px;
        font-family: $semi-bold;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 21px;
        letter-spacing: 0.01em;
        color: #34465e;
        justify-content: space-between;
        cursor: pointer;
      }
      &__body {
        display: flex;
        flex-wrap: wrap;
        overflow-y: auto;
        padding: 0px 10px;
        margin-right: 22px;
        max-height: 175px;
        margin-top: -10px;
        cursor: pointer;
      }
      &__item {
        border: 1px solid #ebeff2;
        font-family: "Poppins-Medium";
        font-style: normal;
        font-weight: 500;
        font-size: 10px;
        width: -webkit-fit-content; /* Chrome */
        width: fit-content;
        padding: 5px;
        margin: 5px;
        border-radius: 5px;
        letter-spacing: 0.02em;
        text-transform: capitalize;
        color: #707683;
        cursor: pointer;
        background-color: #ffff;
      }
    }
  }
}
.Progress {
  padding: 20px;
  &__item {
    &__left {
      padding-bottom: 5px;
      display: flex;
      align-items: center;

      &__utext {
        display: flex;
        flex-basis: 22%;
        flex-direction: column;
        font-family: $font-medium;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 22px;
        /* identical to box height, or 183% */

        letter-spacing: 0.025em;

        /* Table Grey */

        color: #707683;
      }
      &__dtext {
        margin-top: -5px;
        font-family: $semi-bold;
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
        /* identical to box height, or 122% */

        letter-spacing: 0.025em;

        /* Dark Grey */

        color: #34465e;
      }
    }
  }
}
.MealPlannerContainer__content__right__body::-webkit-scrollbar {
  width: 5px;
}

.MealPlannerContainer__content__right__body::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
}

.MealPlannerContainer__content__right__body::-webkit-scrollbar-thumb {
  border-radius: 5px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

.MealPlannerContainer__content__right__item__hide {
  display: none;
}
.MealPlannerContainer__content__right__item:hover
  + .MealPlannerContainer__content__right__item__hide {
  display: flex;
  justify-content: flex-end;
  margin-top: -2.2rem;
  cursor: "pointer";
}
