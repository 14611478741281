.customInput {
  background: #ffffff;
  border: 1px solid #c2cfe0;
  border-radius: 5px;
}
.qty {
  max-width: 30px;
  height: 30px;
  margin-right: 5px;
  &.error-msg {
    border: 1px solid red;
    color: red;
  }
}
