@import "../../../styles/variables.scss";
.notesandremainder {
  display: flex;
  gap: 50px;
}
.notes {
  width: 50%;
  height: 354px;

  background: #ffffff;

  border: 1px solid rgba(194, 207, 224, 0.3);
  box-sizing: border-box;
  border-radius: 10px;
  &__header {
    display: flex;
    padding: 20px;
    border-bottom: 3px solid rgba(194, 207, 224, 0.3);
    &__text {
      padding-left: 10px;
      padding-top: 5px;
      font-family: $semi-bold;
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 22px;
      /* identical to box height, or 138% */

      letter-spacing: 0.025em;

      /* Dark Grey */

      color: #34465e;
    }
  }
  &__body {
    padding-top: 39px;
    padding-left: 25px;

    height: 226px;
    /* padding: 28px; */
    display: flex;
    row-gap: 39px;
    column-gap: 40px;
    flex-wrap: wrap;
    overflow-y: auto;

    &__items {
      width: 45%;
      height: 149px;

      background: rgba(251, 188, 5, 0.1);
      border: 1px dashed rgba(224, 175, 0, 0.2);
      box-sizing: border-box;
      border-radius: 10px;
      &__date {
        padding: 20px;
        font-family: $font-family;
        font-style: normal;
        font-weight: 700;
        font-size: 12px;
        line-height: 18px;
        /* identical to box height */
        display: flex;
        flex-basis: 90%;
        flex: 1 1;
        letter-spacing: 0.02em;

        &__d {
          flex-basis: 90%;
        }

        /* Dark Grey */

        color: #34465e;
      }
      &__text {
        padding-left: 20px;
        border: none;
        resize: none;
        width: 100%;
        height: 92px;
        background-color: inherit;

        /* Body */

        font-family: $font-family;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;

        /* Dark Grey */

        color: #34465e;
      }
    }
  }
  &__footer {
    &__box {
      display: flex;
      border-top: 5px solid rgba(194, 207, 224, 0.3);
    }
  }
}
.remainder {
  width: 50%;
  height: 354px;

  /* White */
  background: #ffffff;

  border: 1px solid rgba(194, 207, 224, 0.3);
  box-sizing: border-box;
  border-radius: 10px;
  &__header {
    display: flex;
    padding: 18px;
    border-bottom: 3px solid rgba(194, 207, 224, 0.3);
    &__text {
      padding-left: 10px;
      padding-top: 5px;
      font-family: $font-family;
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 22px;
      /* identical to box height, or 138% */

      letter-spacing: 0.025em;

      /* Dark Grey */

      color: #34465e;
    }
  }
  &__body {
    height: 224px;
    padding-left: 50px;
    padding-top: 30px;
    overflow: auto;
    &__items {
      display: flex;
      padding: 20px;

      &__utext {
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 16.9673px;
        line-height: 16px;
        /* or 107% */

        color: #413f3f;

        /* Inside auto layout */

        flex: none;
        order: 1;
        flex-grow: 0;
        margin: 0px 8.7019px;
      }
      &__dtext {
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        /* identical to box height, or 107% */

        color: #b5b5ba;
      }
    }
  }
  &__footer {
    &__box {
      display: flex;
      border-top: 5px solid rgba(194, 207, 224, 0.3);
    }
  }
}

.notesimage,
.remainderimage {
  flex-basis: 7%;
  padding: 15px 19px 0px 30px;
}
.notesTextare {
  border: none;
  flex-basis: 76% !important;
  resize: none;
  padding: 15px 0 0 0;
  height: 51px;
  // padding-top: 15px;
}
.addnotesbutton {
  flex-basis: 7%;
  padding-top: 10px;

  border-left: 4px solid rgba(194, 207, 224, 0.3);

  &__button {
    border: none;
    padding-left: 5px;
    background-color: #ffffff;
  }
}
.calenderbutton {
  flex-basis: 8%;
  padding-top: 15px;
  padding-right: 2%;
  border-left: 5px solid rgba(194, 207, 224, 0.3);
}

input[type="checkbox"] {
  &.boxc {
    -webkit-appearance: none;
    appearance: none;
    background-color: white;
    margin: 0;
    font: inherit;
    color: currentColor;
    width: 1.15em;
    height: 1.15em;
    border: 0.15em solid yellow;
    border-radius: 0.15em;
    transform: translateY(-0.075em);
    display: grid;
    place-content: center;
    // accent-color: red;
    &::before {
      content: "";
      width: 0.65em;
      height: 0.65em;
      clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
      transform: scale(0);
      transform-origin: bottom left;
      transition: 120ms transform ease-in-out;

      background-color: black;
    }
    &:checked {
      &::before {
        transform: scale(1);
      }
    }
  }
}

.notes__body__items__date__close {
  display: none;
}
.notes__body__items {
  &:hover {
    .notes__body__items__date__close {
      display: block;
    }
  }
}

.notes__body::-webkit-scrollbar {
  width: 5px;
}

.notes__body::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
}

.notes__body::-webkit-scrollbar-thumb {
  border-radius: 5px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

.remainder__body::-webkit-scrollbar {
  width: 5px;
}

.remainder__body::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
}

.remainder__body::-webkit-scrollbar-thumb {
  border-radius: 5px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}
.underlineText {
  text-decoration-line: line-through;

  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16.9673px;
  line-height: 16px;
  /* identical to box height, or 107% */

  text-decoration-line: line-through;

  color: #b5b5ba;
}

.hide_dtext {
  display: none;
}
.remainder__body__items__utext.completed {
  text-decoration: line-through;
  color: #888;
}

.remainder__body__items__utext .remainder__body__items__dtext {
  font-size: 0.8em;
  color: #666;
}
.remainder__body {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
}

.remainder__body__item {
  display: flex;
  justify-content: start;
  align-items: center;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

.remainder__body__item:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.remainder__body__item.even {
  background-color: #f8f9fa;
}

.remainder__body__item.odd {
  background-color: #ffffff;
}

.remainder__body__item.completed {
  opacity: 0.7;
}

.remainder__body__item__content {
  display: flex;
  align-items: center;
  flex-grow: 1;
}

.remainder__body__item__checkbox {
  margin-right: 15px;
}

.remainder__body__item__text {
  display: flex;
  flex-direction: column;
}

.remainder__body__item__text label {
  font-size: 16px;
  margin-bottom: 5px;
}

.remainder__body__item__date {
  font-size: 12px;
  color: #6c757d;
}

.remainder__body__item__actions {
  display: flex;
  gap: 5px;
}

.remainder__body__item.completed .remainder__body__item__text label {
  text-decoration: line-through;
  color: #6c757d;
}
