.selectInput {
  border: 1px solid #c2cfe0 !important;
  padding: 10px;
  border-radius: 5px;
  background: #fff !important;
}
.MuiInput-underline {
  &:before,
  :after,
  :hover,
  :focus {
    border-bottom: 0px !important;
  }
}
.MuiPaper-root {
  margin-top: 50px !important;
}
.MuiSelect-select {
  height: 28px !important;
  display: flex !important;
  align-items: center;
}
