@import "../../../styles/variables.scss";

.AddWorkoutHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 20px;
  h2 {
    font-family: $font-family;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 0px;
    color: #202c46;
    letter-spacing: 0.025em;
  }
}
.AddWorkoutContainer {
  display: flex;
  .AddWorkout {
    display: flex;
    flex: 1;
    flex-direction: column;
    &.image {
      width: 140px;
      height: 174px;
      padding-right: 15px;
      border-radius: 6px;
      img {
        border-radius: 10px;
      }
    }
    .workoutName {
      font-family: $font-family;
      font-style: normal;
      font-weight: 500;
      font-size: 13.6052px;
      line-height: 20px;
      color: #34465e;
      padding-top: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    label {
      font-family: $font-family;
      font-style: normal;
      font-weight: 400;
      font-size: 9.35358px;
      line-height: 19px;
      letter-spacing: 0.025em;
      color: #707683;
    }
  }
  .workoutContainer {
    display: flex;
    flex: 2;
    flex-direction: column;
    background: #f3f6fa;
    border: 1px dashed #c2cfe0;
    border-radius: 6px;
    padding: 10px;
  }
}

.workoutPlanner {
  padding: 30px;
  box-shadow: azure;

  border-radius: 12px;
}
.workoutPlannerContainer {
  border: 1px dashed #c2cfe0;
  border-radius: 10px;
}
.horizontalScrollableDiv {
  overflow-x: auto;
}
.workoutCalendar {
}
.workoutSchedule {
  border-top: 1px solid #eee;
  padding: 0;
  margin: 0;
}
.workoutTab {
  position: relative;
  min-height: 450px;
}
.workoutGradient {
  background: linear-gradient(
    180deg,
    #fffced 0%,
    rgba(255, 252, 237, 0.18) 100%
  );
  height: 30px;
}
.searchWorkoutListTabContainer {
  padding: 10px;
}
.searchBar {
  margin: 0 50px;
  border: 1px solid #c2cfe0;
  background: #fff;
  border-radius: 5px;
}
.searchWorkoutContainer {
  background: rgba(232, 244, 254, 0.8);
  -webkit-backdrop-filter: blur(30px);
  backdrop-filter: blur(30px);
  top: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.searchWorkoutHeader {
  display: flex;
  justify-content: space-between;
  padding: 0 50px;
  align-items: center;
  h2 {
    font-family: $font-family;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    /* identical to box height, or 122% */
    letter-spacing: 0.025em;

    /* Dark Grey */
    color: #34465e;
  }
}
.AddWorkoutHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 20px;
  h2 {
    font-family: $font-family;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 0px;
    color: #202c46;
    letter-spacing: 0.025em;
  }
}

.inlineWorkout {
  display: flex;
}
