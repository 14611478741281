@import "../../../styles/variables.scss";

.topBarProgress {
  border-collapse: collapse;
  width: 100%;
  border: 1px solid rgba(194, 207, 224, 0.3);
  //   filter: drop-shadow(0px 6px 18px rgba(0, 0, 0, 0.06));
}
.progressIcon {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
}

.topBarProgress {
  display: flex;
  width: 100%;
  height: 60px;
  background-color: #fff;
  align-items: center;
  justify-content: space-between;
  div {
    display: flex;
    /* flex: 1; */
    align-items: center;
    padding: 0 20px;
    span {
      white-space: nowrap;
    }
  }
  &__container {
    flex: 1;
  }
  i {
    padding: 15px;
  }
}

.name {
  font-family: $semi-bold;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  margin-left: 1%;
}

.weightChange {
  // position: relative;
  height: 22px;
  // left: 10.54%;
  // right: 81.35%;
  // top: -37px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 22px;
  display: flex;
  justify-content: space-between;
  /* identical to box height, or 200% */

  letter-spacing: 0.025em;
}
.circumChange {
  // position: relative;
  height: 22px;
  // left: 10.54%;
  // right: 81.35%;
  // top: -31px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 22px;
  display: flex;
  justify-content: space-between;
  /* identical to box height, or 200% */

  letter-spacing: 0.025em;
}

.dailyCaloriesChange {
  // position: relative;
  height: 22px;
  // left: 10.54%;
  // right: 81.35%;
  // top: -25px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 22px;
  /* identical to box height, or 200% */
  display: flex;
  justify-content: space-between;
  letter-spacing: 0.025em;
}

.boxStart {
  border: 0.25px dashed #c2cfe0;
  margin-top: 32px;
}

.start {
  width: 284px;
  height: 0px;
  background: #ffffff;
  top: 14px;
  position: relative;
  left: 25px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 22px;
  /* identical to box height, or 183% */

  letter-spacing: 0.025em;
}

.boxCurrent {
  position: relative;
  top: -18px;
  left: 140px;

  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 22px;
  /* identical to box height, or 183% */

  letter-spacing: 0.025em;
}

.boxGoal {
  position: relative;
  height: 22px;
  left: 45px;
  // right: 77.12%;
  display: flex;
  gap: 10px;
  top: 4px;
  padding-left: 5px;

  font-family: $semi-bold;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 22px;
  /* identical to box height, or 183% */

  letter-spacing: 0.025em;
}

.bicepSvg {
  position: absolute;
  left: -14%;
  right: 23.33%;
  top: 23.33%;
  bottom: 25%;
}

.photoBox {
  display: flex;
  position: relative;
}

.photo {
  background: url("../../../images/bicep.svg");
  width: 150px;
  height: 200px;
  border: 1px solid black;
}

.week {
  position: relative;
  height: 20px;
  left: 16.7%;
  right: 14.41%;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  opacity: 0.5;
}
.weeklyCalendar {
  margin-left: 10px;
}

.month {
  position: relative;
  height: 20px;
  // left: 93.7%;
  right: -18.59%;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  opacity: 0.5;
  width: 52px;
}

.weightGoal {
  height: 22px;
  left: 0%;
  position: relative;
  right: 78.2%;
  top: -2px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  color: #34465e;
}
.imgTag {
  text-align: center;
  width: 189px;
  height: 28px;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.8) 0%,
    rgba(0, 0, 0, 0) 100%
  );
  border-radius: 0px 0px 17px 17px;
  margin-top: -33px;
  margin-left: 25px;

  z-index: 1;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 20px;

  /* identical to box height, or 200% */

  color: #ffffff;

  mix-blend-mode: normal;
  text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
}

.weightStart {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  margin-top: -4px;

  color: #06ab8d;
}

.progressCalender {
  display: flex;
  position: relative;
  top: 64px;
  left: 21px;
  font-family: $semi-bold;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  /* identical to box height, or 138% */

  letter-spacing: 0.025em;

  /* Dark Grey */

  color: #34465e;
}

.progressWeek {
  position: relative;
  font-family: $semi-bold;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 22px;
  /* identical to box height, or 183% */

  letter-spacing: 0.025em;

  /* Orange */

  color: #eb7305;
}

.clientProgress {
  // width: 100%;
  overflow-x: hidden;
  width: 100%;
  background-color: white;
  border-radius: 15px;
  box-shadow: 0px 0px 30px rgb(112 112 112 / 30%);
}

.leftArrow {
  position: relative;

  top: 13px;
  width: 30px;
  height: 30px;
  background: #f2f9ff;
  border: transparent;
  cursor: pointer;
}

.weightChangeData {
  font-family: $semi-bold;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 22px;
  /* identical to box height, or 183% */

  text-align: right;
  letter-spacing: 0.025em;

  /* Dark Grey */

  color: #34465e;
  margin-right: 10px;
}
.clientProgressPlaceholder {
  height: 300px;
  border-radius: 0px 0px 15px 15px;
}

.Content {
  overflow-x: scroll;
  overflow-y: hidden;
  position: relative;
  left: 25px;
  display: flex;
  flex-direction: row;
  padding: 5px;
}

.Content::-webkit-scrollbar {
  width: 5px;
  height: 7px;
}

.Content::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
}

.Content::-webkit-scrollbar-thumb {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  border-radius: 5px;
}
.bar-container {
  border: "1px solid lightgrey";
  height: 420px;
  width: "100%";
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  /* identical to box height, or 167% */

  color: #000000;
}
