@import "../../../styles/variables.scss";
.backtodietplan {
  content: "\003C";
  background: #ffffff;
  align-items: center;
  border: none;
  display: flex;
  font-family: $font-medium;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.00140039px;
  color: #1c78ba;
  padding: 0px 25px;
}
.backtodietplan:hover {
  cursor: pointer;
}
.DietPlanDetail {
  padding: 20px 25px;
  &__header {
    display: flex;
    padding: 0px 25px;
    align-items: center;
    &__text {
      flex: 1;
      color: #000000;
      font-size: 26px;
      font-weight: 500;
      font-family: $font-family;
      font-style: normal;
      line-height: 39px;
    }
  }
  &__container {
    margin-left: 20px;
    height: calc(100vh - 125px);
    background: #ffffff;
    margin-right: 20px;
    padding: 0px 0px 0 0px;
    border: 1px solid rgba(194, 207, 224, 0.3);
    box-sizing: border-box;
    border-radius: 10px;
    display: flex;
    overflow-x: scroll;
    &__card {
      width: 450px;
      border: 1px dashed #c2cfe0;
      background: #ffffff;
      box-sizing: border-box;
      border-radius: 10px;
      margin: 2%;
      &__dietday {
        padding-top: 20px;
        padding-left: 30px;
        width: 100%;
        padding-right: 30px;
        label {
          float: right;
        }
        &__day {
          font-size: 12px;
          font-weight: 500;
          display: flex;
          align-items: center;
        }
        &__duration {
          font-size: 58px;
          line-height: 58px;
          letter-spacing: -2px;
        }
      }
      &__dietbox {
        display: flex;
        align-items: center;
        justify-content: center;
        &__dietcard {
          justify-content: center;
          align-items: center;
          border-radius: 5px;
          box-shadow: 0px 0px 30px 0px #0000001a;
          border: none;
          outline: none;
          padding: 10px;
          margin: 10px !important;
          width: 450px;
          background: #ffffff;
          box-sizing: border-box;
          border-radius: 10px;
          margin: 2%;
          &__mealtype {
            border-radius: 5px;
            border: none;
            outline: none;
            gap: 10px;
            display: flex;
            align-items: center;
            padding: 1px 0px 10px 0px;
            font-family: $font-family;
            font-size: 18px;
            font-weight: 600;
            line-height: 12px;
            letter-spacing: 0.025em;
            text-align: left;
            color: #34465e;
            margin: 0;
          }
          &__dietitems {
            display: flex;
            padding: 10px 0;
            &__img {
              width: 6em;
              height: 6em;
              img {
                width: 100%;
                height: 100%;
                border-radius: 10px;
              }
            }
            &__dietinfo {
              padding: 0 10px;
              width: 80%;
              &__diettitle {
                font-family: $font-family;
                font-style: normal;
                font-weight: 600;
                font-size: 11.6052px;
                line-height: 20px;
                white-space: nowrap;
                overflow-y: hidden;
                text-overflow: ellipsis;
                cursor: pointer;
              }
              &__close {
                display: flex;
                justify-content: flex-end;
                margin-top: -10%;
              }
              &__dietgroup {
                font-family: $font-family;
                font-style: normal;
                font-weight: 400;
                font-size: 9.35358px;
                line-height: 19px;
                color: #707683;
                letter-spacing: 0.025em;
              }
              &__dietservings {
                color: #34465e;
                font-family: $font-family;
                font-style: normal;
                font-weight: 500;
                font-size: 10.2039px;
                line-height: 17px;
                padding: 10px 0;
                letter-spacing: 0.0225em;
                display: flex;
              }
            }
          }
        }
      }
    }
    &__empty {
      justify-content: center;
      align-items: center;
      border-radius: 5px;
      box-shadow: 0px 0px 30px 0px #0000001a;
      border: none;
      outline: none;
      padding: 30px;
      width: 410px;
      background: #ffffff;
      box-sizing: border-box;
      border-radius: 10px;
      &__image {
        border: none;
        background: #ffffff;
      }
    }
  }
}
.addSymbol {
  border: none;
  background-color: #fff;
}
.topBar {
  display: flex;
  width: 100%;
  height: 60px;
  @import "../../../styles/variables.scss";
  .backtodietplan {
    content: "\003C";
    background: #ffffff;
    align-items: center;
    border: none;
    display: flex;
    font-family: $font-medium;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.00140039px;
    color: #1c78ba;
    padding: 0px 25px;
  }
  .backtodietplan:hover {
    cursor: pointer;
  }
  .DietPlanDetail {
    height: 900px;
    padding: 0px 25px;
    &__header {
      display: flex;
      padding: 15px 25px;
      align-items: center;
      &__text {
        flex: 1;
        color: #000000;
        font-size: 26px;
        font-weight: 500;
        font-family: $font-family;
        font-style: normal;
        line-height: 39px;
      }
    }
    &__container {
      margin-left: 20px;
      height: fit-content;
      background: #ffffff;
      margin-right: 20px;
      padding: 0px 0px 0 0px;
      border: 1px solid rgba(194, 207, 224, 0.3);
      box-sizing: border-box;
      border-radius: 10px;
      display: flex;
      overflow-x: scroll;
      &__card {
        width: 450px;
        border: 1px dashed #c2cfe0;
        background: #ffffff;
        box-sizing: border-box;
        border-radius: 10px;
        margin: 2%;
        &__dietday {
          padding-top: 20px;
          padding-left: 30px;
          width: 100%;
          padding-right: 30px;
          label {
            float: right;
          }
          &__day {
            font-size: 12px;
            font-weight: 500;
            display: flex;
            align-items: center;
          }
          &__duration {
            font-size: 58px;
            line-height: 58px;
            letter-spacing: -2px;
          }
        }
        &__dietbox {
          display: flex;
          align-items: center;
          justify-content: center;
          &__dietcard {
            justify-content: center;
            align-items: center;
            border-radius: 5px;
            box-shadow: 0px 0px 30px 0px #0000001a;
            border: none;
            outline: none;
            padding: 10px;
            margin: 10px !important;
            width: 450px;
            background: #ffffff;
            box-sizing: border-box;
            border-radius: 10px;
            margin: 2%;
            &__mealtype {
              border-radius: 5px;
              border: none;
              outline: none;
              gap: 10px;
              display: flex;
              align-items: center;
              padding: 1px 0px 10px 0px;
              font-family: $font-family;
              font-size: 18px;
              font-weight: 600;
              line-height: 12px;
              letter-spacing: 0.025em;
              text-align: left;
              color: #34465e;
              margin: 0;
            }
            &__dietitems {
              display: flex;
              padding: 10px 0;
              &__img {
                width: 6em;
                height: 6em;
                img {
                  width: 100%;
                  height: 100%;
                  border-radius: 10px;
                }
              }
              &__dietinfo {
                padding: 0 10px;
                width: 80%;
                &__diettitle {
                  font-family: $font-family;
                  font-style: normal;
                  font-weight: 600;
                  font-size: 11.6052px;
                  line-height: 20px;
                  white-space: nowrap;
                  overflow-y: hidden;
                  text-overflow: ellipsis;
                  cursor: pointer;
                }
                &__close {
                  display: flex;
                  justify-content: flex-end;
                  margin-top: -10%;
                }
                &__dietgroup {
                  font-family: $font-family;
                  font-style: normal;
                  font-weight: 400;
                  font-size: 9.35358px;
                  line-height: 19px;
                  color: #707683;
                  letter-spacing: 0.025em;
                }
                &__dietservings {
                  color: #34465e;
                  font-family: $font-family;
                  font-style: normal;
                  font-weight: 500;
                  font-size: 10.2039px;
                  line-height: 17px;
                  padding: 10px 0;
                  letter-spacing: 0.0225em;
                  display: flex;
                }
              }
            }
          }
        }
      }
      &__empty {
        justify-content: center;
        align-items: center;
        border-radius: 5px;
        box-shadow: 0px 0px 30px 0px #0000001a;
        border: none;
        outline: none;
        padding: 30px;
        width: 410px;
        background: #ffffff;
        box-sizing: border-box;
        border-radius: 10px;
        &__image {
          border: none;
          background: #ffffff;
        }
      }
    }
  }
  .addSymbol {
    border: none;
    background-color: #fff;
  }
  .topBar {
    display: flex;
    width: 100%;
    height: 60px;
    /* gap: 2px; */
    background-color: #fff;
    align-items: center;
    &__back {
      flex-basis: 7%;
    }
    &__button {
      border: none;
      color: #1c78ba;
      cursor: pointer;

      background-color: #ffffff;
    }
    &__search {
      flex-basis: 95%;
    }
  }

  background-color: #fff;
  align-items: center;
  &__back {
    flex-basis: 7%;
  }
  &__button {
    border: none;
    color: #1c78ba;
    cursor: pointer;
    background-color: #ffffff;
  }
  &__search {
    flex-basis: 95%;
  }
}
