.boxday {
  width: 90px;
  height: 30px;
  margin-left: -19px;
  margin-top: 18px;
  color: #c2cfe0;
}

.calStrr {
  display: flex;
  flex-direction: row;
  border-top: 1px solid #ebeff2;
  height: 100px;
  // width: -90px;
  border: transparent;
  background-color: white;
}

.boxdate {
  width: 90px;
  height: 30px;
  margin-left: -19px;
  color: #34465e;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
}

.buttonCalenderClickToday {
  background-color: #109cf1;
  border-radius: 6px;
  width: 80%;
  justify-content: center;
  display: flex;
  flex-direction: column;
  border: transparent;
  margin-left: 13px;
  margin-right: -65px;
  margin-top: 7px;
  align-items: center;
}

.buttonCalenderClickToday > div {
  color: white;
  margin-left: 1px;
  // margin-top: 5px;
}

.calenderDropdown {
  margin-left: 2px;
  cursor: pointer;
}

.calenderStripItems {
  display: flex;
  flex-direction: row;
  display: flex;
  /* justify-content: space-between; */
  margin-left: 3px;
}

.buttonCalenderClick {
  background-color: white;
  border: transparent;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  margin-left: 3px;
  margin-right: -37px;
  height: 75%;
  width: 85%;
  margin-top: 7px;
  cursor: pointer;
}

.calenderArrow {
  margin-top: 30px;
  margin-left: 10px;
}

.buttonCalenderClick:focus {
  background-color: #109cf1;
  border-radius: 6px;
  width: 85%;
  justify-content: center;
  display: flex;
  // height: 75%;
  margin-left: 11px;
  margin-right: -65px;
  align-items: center;
  //  margin-top: 18px;
}

.buttonCalenderClick:focus > div {
  color: white;
  margin-left: 1px;
  //  margin-top: 5px;
}
.leftArrowCalender {
  cursor: pointer;
  width: 30px;
  height: 30px;
  background: #f2f9ff;
  border: transparent;
}

.topDate {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.calenderStripButton {
  cursor: pointer;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.0225em;
  background-color: transparent;
  outline: none;
  border: transparent;
  color: #34465e;
  margin-right: 16px;
}

.calenderStripHeader {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  border: 1px solid #ebeff2;
}

.calenderStripButtons {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.calDate {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 44px;
  line-height: 66px;
  text-align: right;
  color: #34465e;
  padding: 8px;
  margin-left: 16px;
}

.calDay {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #109cf1;
}

.calMonthYear {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #707683;
}
.selectedDate {
  background-color: #109cf1;
  border-radius: 6px;
  width: 80%;
  justify-content: center;
  display: flex;
  flex-direction: column;
  border: transparent;
  margin-left: 13px;
  margin-right: -65px;
  margin-top: 7px;
  align-items: center;
}
.selectedDate > div {
  color: white;
  margin-left: 1px;
}
