@import "../../../styles/variables.scss";
.Workout {
  border-radius: 10px;
  height: 100%;

  background: linear-gradient(
    180deg,
    #f2f9ff 0%,
    rgba(242, 249, 255, 0.18) 100%
  );

  display: flex;
}

.WorkoutClientContainer {
  margin-left: 40px;
  margin-right: 40px;
  margin-top: 40px;
  margin-bottom: 40px;
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 0px 30px rgba(190, 204, 223, 0.3);
  border-radius: 12px;
  display: flex;
  flex-direction: column;
}

.MealContainer {
  margin-left: 40px;
  margin-right: 40px;
  margin-top: 40px;
  margin-bottom: 40px;
  height: 475px;
  width: 93%;
  background: #ffffff;
  box-shadow: 0px 0px 30px rgba(190, 204, 223, 0.3);
  border-radius: 12px;
  display: flex;
  flex-direction: column;
}

.workoutHeader {
  display: flex;
  flex-direction: row;
  /* align-items: center; */
  /* justify-content: unset; */
  /* text-align: center; */
  padding: 10px;
  margin-left: 20px;
  margin-top: 10px;
}

.workoutBoxItems {
  display: flex;
  padding: 50px;
  justify-content: space-between;
  &__left {
    display: flex;
    flex-direction: column;
    flex-basis: 50%;
    justify-content: space-around;
    margin-top: 30px;
    margin-left: 32px;
    margin-bottom: 30px;
  }
}

.workoutBoxItem {
  align-items: center;

  display: flex;
  flex-direction: row;
  background: #ffffff;
  /* Grey */
  border: 1px dashed #c2cfe0;
  border-radius: 10px;

  height: 72px;
}

.workoutInfoFooter {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.workoutInfoHeaderText {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  /* identical to box height, or 138% */

  letter-spacing: 0.025em;

  /* Dark Grey */

  //   color: #34465e;
  margin-left: 15px;
}

.lineBelowWorkoutHeader {
  background: #ffffff;
  border: 0.0001px solid rgba(194, 207, 224, 0.3);
  width: 100%;
  /* margin-top: 33px; */
  width: 100%;
}

.workoutFooterHeader {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.025em;
  color: #34465e;
  padding: 21px;
  margin-top: 25px;
}

.workoutFooterPara {
  margin-left: 21px;
}

.boxDetail {
  display: flex;
  flex-direction: row;
  margin-left: 27px;
  white-space: nowrap;
  width: 30%;
}

.boxDetailHeader {
  font-family: "Poppins";
  font-style: normal;
  font-weight: bolder;
  font-size: 12px;
  line-height: 22px;
  letter-spacing: 0.025em;
  width: fit-content;
  color: #707683;
}

.boxDetailHeaderInfo {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.025em;
  display: block;
  color: #34465e;
}

.boxIcon {
  margin-left: 25px;
}
.WorkoutCalendarContainer {
  margin-bottom: 50px;
  background: #ffffff;
  /* Shadow */

  border-radius: 12px;

  &__content {
    display: flex;
    gap: 45px;
    justify-content: space-evenly;

    &__right {
      width: 35%;
      height: 265px;
      margin-top: 30px;
      margin-right: 32px;
      margin-bottom: 30px;
      box-sizing: border-box;
      border-radius: 10px;
      background: rgba(247, 255, 250, 0.8);
      border: 1px dashed #c2cfe0;
      &__header {
        display: flex;
        height: 50px;
        padding: 20px 20px;
        font-family: $semi-bold;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 21px;
        letter-spacing: 0.01em;
        color: #34465e;
        justify-content: space-between;
      }
      &__body {
        max-height: 200px;
        display: flex;
        flex-wrap: wrap;
        overflow-y: auto;
        padding: 10px 10px;
        margin-right: 25px;
      }
      &__item {
        border: 1px solid #ebeff2;
        font-family: "Poppins-Medium";
        font-style: normal;
        font-weight: 500;
        font-size: 10px;
        padding: 5px;
        margin: 5px;
        border-radius: 5px;
        letter-spacing: 0.02em;
        text-transform: capitalize;
        color: #707683;
        cursor: pointer;
      }
    }
  }
}

.WorkoutCalendarContainer__content__right__item__hide {
  display: none;
}
.WorkoutCalendarContainer__content__right__item:hover
  + .WorkoutCalendarContainer__content__right__item__hide {
  display: flex;
  justify-content: flex-end;
  margin-top: -2.2rem;
  cursor: "pointer";
}
