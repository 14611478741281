.customButton {
  background: #109cf1;
  border-radius: 5px;
  border: 0px;
  padding: 5px 10px;
  margin: 3px;
  color: #fff;
  cursor: pointer;
}
.customButton:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}
.defaultButton {
  background: #e0e8ed;
  border-radius: 5px;
  border: 0px;
  padding: 5px 10px;
  margin: 3px;
  color: #fff;
}
