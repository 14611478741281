.topnav {
  display: flex;
  width: 100%;
  height: 60px;
  background-color: #fff;
  align-items: center;
  &__container {
    flex: 1;
  }
  i {
    padding: 15px;
  }
}

.leadSearchBar {
  width: 800px;
  height: 50px;
  border-color: transparent;
  font-weight: 400;
font-size: 13px;
}

.leadSearchBar:focus {
  outline: transparent;
}
