.spinner {
  width: 100%;
  height: 100%;
  position: fixed;
  /* top: 0; */
  display: flex;
  /* display: flex; */
  align-items: center;
  justify-content: center;
  -webkit-backdrop-filter: blur(2px);
  backdrop-filter: blur(1px);
  z-index: 9;
}

.spinner > div {
  display: flex;
  justify-content: center;
  align-items: center;
}
