.Workout {
  border-radius: 10px;
  height: 100%;

  background: linear-gradient(
    180deg,
    #f2f9ff 0%,
    rgba(242, 249, 255, 0.18) 100%
  );

  display: flex;
}

.WorkoutContainer {
  margin-left: 40px;
  margin-right: 40px;
  margin-top: 40px;
  margin-bottom: 40px;
  height: 475px;
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 0px 30px rgba(190, 204, 223, 0.3);
  border-radius: 12px;
  display: flex;
  flex-direction: column;
}

.MealContainer {
  margin-left: 40px;
  margin-right: 40px;
  margin-top: 40px;
  margin-bottom: 40px;
  height: 475px;
  width: 93%;
  background: #ffffff;
  box-shadow: 0px 0px 30px rgba(190, 204, 223, 0.3);
  border-radius: 12px;
  display: flex;
  flex-direction: column;
}

.workoutHeader {
  display: flex;
  flex-direction: row;
  /* align-items: center; */
  /* justify-content: unset; */
  /* text-align: center; */
  padding: 10px;
  margin-left: 20px;
  margin-top: 10px;
}

.workoutInfoBoxItems {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin-top: 40px;
}

.workoutInfoBoxItem__Dashboard {
  align-items: center;

  display: flex;
  flex-direction: row;

  /* Grey */

  border-radius: 10px;
  width: 31%;
  height: 100px;
  background: #ffffff;
  /* Opacity Grey */

  border: 1px solid rgba(194, 207, 224, 0.3);
  box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
}

.workoutInfoFooter {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.workoutInfoHeaderText {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  /* identical to box height, or 138% */

  letter-spacing: 0.025em;

  /* Dark Grey */

  color: #34465e;
  margin-left: 15px;
}

.lineBelowWorkoutHeader {
  background: #ffffff;
  border: 0.0001px solid rgba(194, 207, 224, 0.3);
  width: 100%;
  /* margin-top: 33px; */
  width: 100%;
}

.workoutFooterHeader {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  /* identical to box height, or 122% */

  letter-spacing: 0.025em;

  /* Dark Grey */

  color: #34465e;
  padding: 21px;
  margin-top: 25px;
}

.workoutFooterPara {
  margin-left: 21px;
}

.boxDetail {
  display: flex;
  flex-direction: row;
  margin-left: 27px;
  white-space: nowrap;
  gap: 10px;
}

.boxDetailHeader__Dashboard {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */

  letter-spacing: 0.00260073px;

  /* Table Grey */

  color: #707683;
}

.boxDetailHeaderInfo__Dashboard {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 45px;
  /* identical to box height */

  letter-spacing: 0.00260073px;

  /* Black */

  color: #000000;
}

.boxIcon {
  margin-left: 25px;
}

.lowerPartDashboard {
  display: flex;
  flex-direction: row;
  margin-top: 30px;
  gap: 37px;
}

.notificationList {
  display: flex;
  flex-direction: column;
  background: #ffffff;
  /* Opacity Grey */

  border: 1px solid rgba(194, 207, 224, 0.3);
  box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
  border-radius: 10px;
  width: 47%;
  height: 435px;
  margin-left: 20px;
}
.reminderList {
  display: flex;
  flex-direction: column;
  background: #ffffff;
  /* Opacity Grey */

  border: 1px solid rgba(194, 207, 224, 0.3);
  box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
  border-radius: 10px;
  width: 47%;
  height: 435px;
  margin-right: 15px;
}

.notificationListTitleIcon {
  display: flex;

  align-items: flex-start;
  padding: 28px;
  gap: 13px;

  height: 75px;

  /* Gray/White */
  border: 1px solid rgba(194, 207, 224, 0.3);

  background: #ffffff;
  /* Border / Bottom */

  box-shadow: 0px 1px 0px #e4e8ee;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}

.notificationListTitle {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 15.5px;

  /* Gray/White */
  border: 1px solid rgba(194, 207, 224, 0.3);

  background: #ffffff;
  /* Border / Bottom */

  box-shadow: 0px 1px 0px #e4e8ee;

  /* Inside auto layout */
  gap: 10px;
  flex: none;
  order: 0;
  flex-grow: 0;
  &__data {
    display: flex;
    flex-direction: column;
  }
  &__check {
    padding-top: 8px;
  }
}

.reminderLists {
  /* White */
  display: flex;
  flex-direction: column;
  background: #ffffff;
  /* Opacity Grey */

  border: 1px solid rgba(194, 207, 224, 0.3);
  box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
  border-radius: 10px;
  width: 47%;
  height: 435px;
  // height: 300px;
  margin-right: 20px;
  &__header {
    display: flex;

    align-items: flex-start;
    padding: 28px;
    gap: 13px;

    height: 75px;

    /* Gray/White */
    border: 1px solid rgba(194, 207, 224, 0.3);

    background: #ffffff;
    /* Border / Bottom */

    box-shadow: 0px 1px 0px #e4e8ee;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    /* Inside auto layout */
    &__title {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      /* identical to box height, or 138% */

      letter-spacing: 0.025em;

      /* Dark Grey */

      color: #34465e;
    }
  }
  &__bodydashboard {
    height: 300px;
    overflow: auto;

    background-color: #ffff;
    box-shadow: 0px 2px 0px #e4e8ee;
  }
  &__bodydashboardHidden {
    height: 300px;

    background-color: #ffff;
    box-shadow: 0px 2px 0px #e4e8ee;
  }
  &__footerdashboard {
    display: flex;
    padding: 20px;
    justify-content: center;
    height: 54px;

    // border: 1px solid rgba(194, 207, 224, 0.3);
  }
}

.calenderIcons {
  display: flex;
}

.topDashboard {
  display: flex;
  margin-top: 40px;
  justify-content: space-between;
}

.dashboardTitle {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 26px;
  line-height: 39px;
  /* identical to box height */

  letter-spacing: 0.00260073px;

  color: #000000;
  padding: 20px;
}

.dashboardTitleDate {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 26px;
  /* identical to box height */

  letter-spacing: 0.00260073px;

  color: #707683;
}

.viewAllRemiders {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */

  text-align: center;
  letter-spacing: 0.01em;

  /* Light Blue */

  color: #109cf1;
  cursor: pointer;
}

.reminderDetails {
  width: 437px;
  height: 18px;

  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14.9673px;
  line-height: 16px;
  /* or 107% */

  color: #413f3f;
  padding-top: 6px;
  &__bold {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;

    color: #000000;
  }
}

.reminderFinishBy {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 24px;
  /* identical to box height */

  letter-spacing: 0.00260073px;

  /* Table Grey */

  color: #707683;
}
.noReminder {
  justify-content: center;
  display: flex;
  padding: 11%;
}
.color {
  color: #b5b5ba;
}
