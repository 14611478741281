@import "../../../styles/variables.scss";

.RecipieIngridient {
  background: #ffff;
  border: 1px solid rgba(194, 207, 224, 0.3);
  box-sizing: border-box;
  margin: 30px;
  padding: 30px;
  box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);

  &__heading {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    padding-left: 50px;
    padding-top: 10px;
    padding-right: 10px;
    padding-bottom: 30px;
    color: #202c46;
    &__image {
      cursor: pointer;
      float: right;
      margin-right: 10px;
      margin-top: 5px;
    }
  }
  &__HeaderText {
    padding-bottom: 20px;
    padding-top: 20px;
    display: "flex";
    justify-content: center;
    align-items: center;
    text-align: center;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.025em;
  }

  &__preparation {
    display: flex;
    justify-content: flex-start;
    padding-left: 30px;
    &__image {
      width: 15em;
      height: 15em;
      margin-left: 20px;
      img {
        width: 100%;
        height: 100%;
        border-radius: 10px;
      }
    }
    &__counts {
      padding-left: 60px;
      width: 75%;
      span {
        display: flex;
        padding: 20px;
      }
      &__amount {
        width: fit-content;
      }
    }
  }

  .FoodTable {
    display: flex;
    column-gap: 6px;
    padding: 10px;
  }
}

.foodImage {
  width: 6em;
  height: 6em;
  img {
    width: 100%;
    height: 100%;
    border-radius: 10px;
  }
}
.foodName {
  padding-left: 30px;
  display: "flex";
  justify-content: "center";
  align-items: "center";
  font-size: 13px;
  font-weight: bold;
}
.foodAmount {
  display: "flex";
  justify-content: "center";
  align-items: "center";
  font-size: 10px;
}
.recipeInfo {
  padding: 0 10px;
  width: 450px;
}
.recipeName {
  font-family: $font-family;
  font-style: normal;
  font-weight: 600;
  font-size: 11.6052px;
  line-height: 20px;
  white-space: nowrap;
}
.foodGroup {
  font-family: $font-family;
  font-style: normal;
  font-weight: 400;
  font-size: 9.35358px;
  line-height: 19px;
  color: #707683;
  letter-spacing: 0.025em;
}
.foodsServings {
  color: #34465e;
  font-family: $font-family;
  font-style: normal;
  font-weight: 500;
  font-size: 10.2039px;
  line-height: 17px;
  padding: 10px 0;
  letter-spacing: 0.0225em;
}
.FoodScroll {
  overflow-y: scroll;
  width: 1000px;
  height: 500px;
}
.ingredients {
  padding: 0px 10px;
}
.ingredients__text__cal {
  padding-top: 5px;
  padding-left: 5px;
}
.ingredients__text__bigplus {
  padding-bottom: 10px;
}
.ingredients__button {
  padding-left: 5px;
  padding-top: 7.5px;
  width: 10px;
  height: 10px;
}
.ingredients__text {
  display: flex;

  align-items: center;
}
.ingredients__text h2 {
  font-family: $font-family;
  font-size: 18px;
  font-weight: 600;
  line-height: 12px;
  letter-spacing: 0.025em;
  text-align: left;
  color: #34465e;
  margin: 0;
}
.ingredients__text label {
  font-family: $semi-bold;
  font-size: 10px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0.025em;
  text-align: right;
  color: #34465e;
}
.recipeInputBox {
  height: 50px;
  width: 400px;
  border: 1px solid #c2cfe0;
  box-sizing: border-box;
  border-radius: 5px;
  margin-right: 0px;
  padding-left: 5px;
  resize: none;
  color: #34465e;
  padding: 7px;
}
.recipeSteps__delete {
  padding-top: 15px;
  padding-right: 5px;
}
.recipeSteps__drag {
  padding-top: 12px;
  padding-right: 5px;
}
.recipeSteps {
  display: flex;

  &__heading {
    display: flex;
    flex-flow: column;
    padding-top: 50px;
    color: black;
    gap: 30px;
    font-family: "Poppins";
    font-size: 18px;
    font-weight: 600;
    line-height: 12px;
    letter-spacing: 0.025em;
    text-align: left;
    color: #34465e;
    margin: 0;
    &__box {
      display: flex;
      &__text {
        padding: 5px;
      }
    }
  }
}
.recipeInputBox {
  width: 700px;
  line-height: 1.5;
  resize: none;
}
