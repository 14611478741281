@import "../../styles/variables.scss";
.clients {
  &__header {
    display: flex;
    padding: 30px 30px 0 30px;
    justify-content: space-between;
    h1 {
      color: #000000;
      font-size: 26px;
      font-weight: 500;
      font-family: $font-medium;
      line-height: 39px;
      font-style: normal;
      flex: 2;
    }
    &__content {
      display: flex;
      align-items: center;
    }
  }
  .filterbtn {
    font-family: $font-family;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 19px;
    letter-spacing: 0.00130037px;
    color: #109cf1;
  }
  .createnewbtn {
    font-family: $font-family;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 19px;
    letter-spacing: 0.00130037px;
    color: #ffffff;
    flex-shrink: 0;
    margin: 0 5px;
  }
  &__container {
    padding: 30px;
  }
}

.gridTable {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  grid-auto-rows: minmax(100px, auto);
  margin-top: 33px;
}

.Library__body {
  align-items: center;

  display: flex;
  flex-direction: row;

  /* Grey */

  border-radius: 10px;
  width: 31%;
  height: 100px;
  background: #ffffff;
  /* Opacity Grey */

  border: 1px solid rgba(194, 207, 224, 0.3);
  box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
  &__text {
    color: #1c78ba;
    border: none;
    background: #ffffff;
    font-family: Poppins-Medium;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    margin-left: 10px;
  }
}

.Library {
  padding: 30px 30px 0 30px;
  display: flex;
  gap: 50px;
}
