.failureContainer {
  //margin
  margin-left: 200px;
  margin-right: 200px;
  margin-top: 50px;
  margin-bottom: 50px;
  padding-left: 50px;
  padding-right: 50px;
  display: flex;
  justify-content: center;
  &__left {
    margin: 20px;
    background-color: white;
    box-shadow: 0px 4px 4px rgba(181, 181, 181, 0.25);
    width: 60%;

    &__footer {
      padding-top: 55px;
      padding-bottom: 50px;
      &__topText {
        padding-left: 55px;
        font-family: "Arial";
        font-style: normal;
        font-weight: 700;
        font-size: 30px;
        line-height: 34px;
        /* identical to box height */

        color: red;
      }
      &__middleText {
        padding-left: 55px;
        padding-top: 20px;
        width: 90%;

        font-family: "Arial";
        font-style: normal;
        font-weight: 400;
        font-size: 17px;
        line-height: 23px;

        color: #34465e;
        &__top {
          padding: 20px 0px 0px;
        }
        &__middle {
          padding: 20px 0px 0px;
        }
        &__bottomtext {
          padding-top: 20px;
        }
        &__bottomemail {
          padding: 0px 0px 35px 0px;
        }
      }
    }
  }
}
.payImage {
  width: 100%;
  height: 80%;
}

/* Extra Small Devices, Phones */
@media only screen and (max-width: 768px) {
  .paymentcontainer {
    display: flex;
    margin: 0%;
    padding: 10px;
    flex-direction: column;
    &__left {
      width: 100%;
      margin: 0%;
    }
    &__right {
      width: 100%;
    }
  }
}

/* Small Devices, Tablets */
@media only screen and (min-width: 768px) and (max-width: 992px) {
  .paymentcontainer {
    display: flex;
    margin: 0%;
    padding: 50px;
    flex-direction: column;
    &__left {
      width: 100%;
      margin: 0%;
    }
    &__right {
      width: 100%;
    }
  }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .paymentcontainer {
    display: flex;
    margin: 0%;
    padding: 50px;
    flex-direction: column;
    &__left {
      width: 100%;
      margin: 0%;
    }
    &__right {
      width: 100%;
    }
  }
}
