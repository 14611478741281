@import "../../styles/variables.scss";

.inputContainer {
  width: 100%;
  label {
    font-family: $font-family;
    font-style: normal;
    font-weight: 500;
    font-size: 13.6052px;
    line-height: 20px;
    display: flex;
    align-items: center;

    /* Dark Grey */

    color: #34465e;
  }
}
