.LayoutLibrary__body {
  display: flex;
  gap: 10px;
  padding-left: 30px;
  padding-top: 20px;
  padding-right: 10px;
  &__text {
    &__input {
    }
  }
}
.AddMeals__button {
  color: #1c78ba;
  background-color: transparent;
  background-image: none;
  border-color: #1c78ba;
}

.layoutsavebtn {
  cursor: pointer;

  float: right;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  margin-right: 55px;
  color: #ffff;
  width: 15%;
  height: 10%;
  background-color: rgb(16, 156, 241);
  border: 1px solid rgb(16, 156, 241);
  border-radius: 5px;
  &__container {
    display: flex;
    justify-content: end;
    padding-right: 40px;
    padding-top: 20px;
  }
}
.layout__list {
  display: flex;
  flex-flow: column;
  padding-top: 50px;
  color: black;
  align-items: center;
  gap: 30px;
  &__names {
    gap: 10px;
    background-color: #1c78ba;
    width: 40%;
    padding: 20px;
    display: flex;
    justify-content: start;
    text-align: center;
    font-size: 20px;
    height: 0px;
    cursor: move;
    &__title {
      padding-left: 20%;
      color: #ffff;
      font-size: 15px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    &__delete {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.Editmodal-layoutlist {
  position: fixed;
  background: white;
  width: 600px;
  height: 300px;
  top: 50%;
  left: 50%;
  box-shadow: 0px 0px 50px rgba(255, 255, 255, 0.3);
  border-radius: 10px;
  transform: translate(-50%, -50%);
}

.LayoutLibrary__body {
  display: flex;
  gap: 25px;
  padding-left: 50px;
  padding-top: 70px;
  padding-right: 10px;
  &__text {
    color: #34465e;
    font-size: 15px;

    &__input {
    }
  }
}
.AddMeals__button {
  color: #1c78ba;
  background-color: transparent;
  background-image: none;
  border-color: #1c78ba;
  width: 20%;
}

.layoutsavebtn {
  cursor: pointer;

  float: right;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  margin-right: 55px;
  color: #ffff;
  width: 25%;
  height: 30px;
  background-color: rgb(16, 156, 241);
  border: 1px solid rgb(16, 156, 241);
  border-radius: 5px;
  &__container {
    display: flex;
    justify-content: end;
    padding-right: 0px;
    padding-top: 40px;
  }
}

.layouts-container {
  padding: 60px;
  // height: 250px;
  // overflow-y: auto;
}
.list {
  columns: 2;
  column-gap: 10px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  height: auto;
  width: 800px;
}
.customButton:disabled {
  opacity: 0.7;
  cursor: not-allowed;
  width: 20%;
}
.AddMeals__button {
  color: #1c78ba;
  background-color: transparent;
  background-image: none;
  border-color: #1c78ba;
  width: 20%;
  cursor: pointer;
}
.saveEditLayout {
  justify-content: end;
  display: flex;
  padding-right: 10%;
  padding-top: 10%;
  margin: 20px;
}
.editButton {
  background: #109cf1;
  border-radius: 5px;
  border: 0px;
  padding: 5px 80px;
  margin: 3px;
  color: #fff;
  cursor: pointer;
}