@import "../../styles/variables.scss";
.dashboard {
  height: calc(100vh - 60px);
  /* display: flex; */
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 6px;

  h2 {
    font-family: Poppins;
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 60px;
    text-align: center;
    letter-spacing: 0.01em;
    width: 310px;
    color: #34465d;
    opacity: 0.5;
  }
}

.dashboard_container {
  padding: 10px;
}
.reminderCalenderTop {
  display: flex;
  background: white;
  justify-content: space-between;
}

.reminderTitle {
  display: flex;
  background-color: white;
  /* border: 1px solid lightgray; */
  width: 100%;
  /* height: 238%; */
  padding: 17px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 21px;
  text-align: center;
  letter-spacing: 0.01em;
  color: black;
}
.backtodashboard {
  content: "\003C";
  background: #ffffff;
  align-items: center;
  border: none;
  margin-left: 10px;
  font-family: $font-medium;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.00140039px;
  color: #1c78ba;
  cursor: pointer;
}