.Food__body {
  display: flex;
  justify-content: start;
  padding-top: 30px;
  padding-left: 30px;
  width: 100%;
  overflow: auto;
}

.foodlibrary {
  &__header {
    display: flex;
    justify-content: space-between;
    padding: 30px 30px 0 30px;
    &__text {
      flex-basis: 64.3%;
    }
    &__button {
      display: flex;
      align-items: center;
      gap: 5px;
    }
  }
}
.scrollableContainer {
  width: 100%;
  overflow: scroll;
  display: flex;
}
.footitle__Text {
  font-size: 25px;
  font-weight: 600;
  padding-left: 5px;
}
.searchBar {
  margin: 0px 30px !important;
}
