@import "../../../styles/variables.scss";
$small: 600px;
$medium: 900px;

.mealsPlanner {
  padding: 30px;
  box-shadow: azure;

  border-radius: 12px;
}
.mealsPlannerContainer {
  border: 1px dashed #c2cfe0;
  border-radius: 10px;
}
.horizontalScrollableDiv {
  overflow-x: hidden;
}
.mealsCalendar {
}
.mealsSchedule {
  border-top: 1px solid #eee;
  padding: 0;
  margin: 0;
}
.mealsTab {
  position: relative;
  min-height: 450px;
}
.mealsGradient {
  background: linear-gradient(
    1800deg,
    #fffced 0%,
    rgba(255, 252, 237, 0.18) 100%
  );
  height: 30px;
  padding: 10px;
}
.mealBox1 {
  border-radius: 5px;
  box-shadow: 0px 0px 30px 0px #0000001a;
  border: none;
  outline: none;
}
.searchFoodListTabContainer {
  padding: 10px;
}
.searchBar {
  margin: 0 50px;
  border: 1px solid #c2cfe0;
  background: #fff;
  border-radius: 5px;
}
.searchFoodContainer {
  background: rgba(232, 244, 254, 0.8);
  -webkit-backdrop-filter: blur(30px);
  backdrop-filter: blur(30px);
  top: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.searchFoodHeader {
  display: flex;
  justify-content: space-between;
  padding: 0 50px;
  align-items: center;
  h2 {
    font-family: $font-family;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    /* identical to box height, or 122% */
    letter-spacing: 0.025em;

    /* Dark Grey */
    color: #34465e;
  }
}
.AddMealHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 20px;
  h2 {
    font-family: $font-family;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 0px;
    color: #202c46;
    letter-spacing: 0.025em;
  }
}
.AddMealContainer {
  display: flex;
  height: 550px !important;
  .AddMeal {
    display: flex;
    flex: 1;
    flex-direction: column;
    &.image {
      width: 140px;
      height: unset !important;
      padding-right: 15px;
      border-radius: 6px;
      img {
        border-radius: 10px;
        height: 250px;
      }
    }
    .mealName {
      font-family: $font-family;
      font-style: normal;
      font-weight: 500;
      font-size: 13.6052px;
      line-height: 20px;
      color: #34465e;
      padding-top: 10px;
    }
    label {
      font-family: $font-family;
      font-style: normal;
      font-weight: 400;
      font-size: 9.35358px;
      line-height: 19px;
      letter-spacing: 0.025em;
      color: #707683;
    }
  }
  .mealContainer {
    display: flex;
    overflow-x: hidden;
    flex: 2;
    flex-direction: column;
    background: #f3f6fa;
    border: 1px dashed #c2cfe0;
    border-radius: 6px;
    padding: 10px;
    height: 512px !important;
  }
}
.inputContainer {
  padding: 10px !important;
}
.react-datepicker-wrapper {
  .react-datepicker__input-container {
    input[type="text"] {
      width: 100%;
    }
  }
}
.react-datepicker {
  &__week {
    background: inherit !important;
  }
  &__day {
    &--disabled {
      background: inherit !important;
    }
  }
  &__in-range {
    background: #0d9cf0;
  }
}
.inline {
  display: flex;
  width: 100%;
}
.inline__servings {
  display: flex;
  width: 100%;
  padding-left: 11px;
}
.modalFooter {
  display: flex;
  justify-content: flex-end;
  padding: 10px 0;
}
.addFood {
  font-family: "Poppins" !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 12px !important;
  line-height: 28px !important;
  align-items: center !important;
  color: #fff !important;
  margin: 0 30px !important;
  /* outline: none; */
  border: none;
  display: flex;
  background: #0d9cf0 !important;
  float: right;
  span {
    display: flex;
    gap: 5px;
    align-items: center;
  }
}
.MealsContainer {
  display: flex;
  flex: 2;
  flex-direction: column;
  background: #f3f6fa;
  border: 1px dashed #c2cfe0;
  border-radius: 6px;
  padding: 10px;
  height: 70vh;
  overflow-y: hidden;
}
.form-group {
  // margin-bottom: 30px;
}
.form-group-link {
  display: flex;
  text-decoration: underline;
  justify-content: center;
}
.foodrows {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  padding-bottom: 10px;
}
.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  width: 100%;
  flex-basis: 35%;
  font-size: inherit;
  margin-left: 8.333333%;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}
.col-mg {
  flex-basis: 10%;
}
.meals-heading {
}
.marginRight {
  margin-right: 5px;
}
.marginLeft {
  margin-left: 5px;
}
.vitamins {
  width: 90%;
}
.add-inputbox {
  .sm {
    margin-right: 10px;
    margin-right: 10px;
    padding-left: 100px;
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
    flex-wrap: wrap;
    padding-bottom: 10px;
    .foodrows {
      display: flex;
      width: 100%;
      height: 0px;
    }
    input {
      width: 60px;
      margin: 0 10px;
      padding: 5 10px;
    }
  }
}
.form-control {
  border: 1px solid #ced4da !important;
  height: 38px;
  box-sizing: border-box;
  border-radius: 5px;
  margin-right: 40px;
}
.meal_modal_heading {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  width: 100%;
  flex-basis: 35%;
  font-size: 23px;
  margin-left: 30px;
  margin-bottom: 1rem;
  font-weight: 600;
  line-height: 3.2;
}
.a-meals-link {
  cursor: pointer;
}
.sugar_type {
  padding-bottom: 20px !important;
}
.nutritionFacts {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.MealColumn {
  display: grid;
  flex-wrap: wrap;
  grid-template-columns: repeat(1, 1fr);
  /* margin: 10px; */
  /* padding: 10px; */
  gap: 10px;
  @media screen and (max-width: $small) {
    //do Smth

    grid-template-columns: repeat(1, 1fr);
  }
  @media screen and (min-width: 1100px) {
    //do Smth
    grid-template-columns: repeat(2, 1fr);
  }
  @media screen and (min-width: 1330px) {
    //do Smth
    grid-template-columns: repeat(2, 1fr);
  }
}
.childColumn {
  max-width: 2300px;
}
.noMeals {
  display: flex;
  justify-content: center;
  padding-top: 150px;
  align-items: center;
  flex-flow: column;
  gap: 20px;
}
.searchMealButton {
  background: #109cf1;
  border-radius: 5px;
  border: 1px solid white;
  color: white;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 20px;
  letter-spacing: 0.00130037px;
  height: 30px;
  width: 150px;
}

.searchFood {
  background: #ffffff;
  /* Grey */
  border: 1px solid #c2cfe0;
  box-sizing: border-box;
  border-radius: 5px;
  color: #c2cfe0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 10px;
  cursor: pointer;
}
.mealTop {
  padding: 5px;
  display: flex;
  justify-content: space-between;
  margin: 30px;
  font-family: "Poppins";
  font-size: 18px;
  font-weight: 600;
  line-height: 12px;
  letter-spacing: 0.025em;
  text-align: left;
  color: #34465e;
}
