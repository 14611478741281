.chat {
  overflow-y: auto;
  padding: 10px;

  height: 400px;

  display: flex;
  flex-direction: column-reverse;
  width: 100%;

  &__message {
    direction: ltr;

    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    position: relative;
    word-break: break-word;
    & div.info {
      display: flex;
      flex-direction: unset;
      & div.date {
        color: #0e0e10;
        font-size: 12px;
        font-family: "Poppins";
        display: flex;
        align-items: center;
        padding-left: 10px;
      }
    }
    & div.msg {
      border-radius: 25px;
      color: #000;
      background-color: #00a9de;
      max-width: 600px;
      margin: 5px 0 20px 0;
      padding: 10px 20px;
      font-size: 18px;
      border-radius: 0px 10px 10px 10px;
    }

    &-own {
      margin-left: auto;
      & div.info {
        display: flex;
        flex-direction: unset;
        justify-content: flex-end;
      }
      & div.msg {
        color: #000;
        border-radius: 10px 10px 0px 10px;
        background-color: #e6e7ec;
      }
    }
  }
  // transform: rotate(180deg);
  // direction: rtl;
}
.chatInput {
  display: flex;
  align-items: center;
  box-shadow: 0px 1px 5px rgb(0 0 0 / 25%);
  border-radius: 5px;
  & div.emailContainer {
    width: 100%;
    height: 215px;
    border: 0;
    padding: 10px;
    border-right: 1px solid rgb(0 0 0 / 25%);
    &:focus {
      outline: none;
    }
  }
  & svg {
    margin: 0 10px;
  }
  // .subject {
  //   display: none;
  //   &.active {
  //     display: block;
  //   }
  // }
}

.textarea1 {
  border: none;
  outline: none;
  border-bottom: 1px solid grey;
  width: 95%;

  font-size: 14px;
}
.textarea2 {
  border: none;
  outline: none;
  border-bottom: 1px solid grey;
  width: 95%;
  padding: 10px 0px 0px 2px;
  font-size: 14px;
  resize: none;
  height: 150px;
}
.emailContainer {
  width: 100%;
  border: 0;
  padding: 10px;
}
.emailContainer-left {
  display: flex;
  flex-flow: row;
  &__textArea {
    flex-basis: 90%;
  }
  &__button {
    padding-top: 150px;
  }
}
.body {
  rows: "3";
}
.send {
  border: none;
  background: #06ab8d;
  color: #fff;
  border-radius: 5px;
  height: 40px;
}
.sendButton {
  width: 58px;
  height: 34px;
  border-radius: 10px;
  color: white;
  background-color: #06ab8d;
  border: none;
  animation-name: sendButton;
  animation-duration: 1s;
  cursor: pointer;
}

@keyframes sendButton {
  from {
    margin-left: 10px;
  }

  to {
    margin-left: 0%;
  }
}

.pagination-container {
  .pagination-item {
    &.arrowIcon {
      &.right {
        transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);
      }
      border: solid black;
      border-width: 0 3px 3px 0;
      display: inline-block;
      padding: 3px;
    }
  }
}
.info {
  display: flex;
  gap: 5px;
  &__admin {
    display: flex;
    gap: 5px;
    justify-content: end;
  }
}
.toAddress {
  padding-top: 10px;
  padding-bottom: 10px;
}
