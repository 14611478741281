@import "../../styles/variables.scss";
.leads {
  &__header {
    display: flex;
    padding: 30px 30px 0 30px;
    justify-content: space-between;
    h1 {
      flex: 1;
      color: #000000;
      font-size: 26px;
      font-weight: 500;
      font-family: $font-medium;
      font-style: normal;
      line-height: 39px;
    }
    &__text {
    }
    &__buttons {
      display: flex;
      align-items: center;
      gap: 10px;
    }
  }
  .filterbtn {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 19px;
    letter-spacing: 0.00130037px;
    color: #109cf1;
  }
  .createnewbtn {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 19px;
    letter-spacing: 0.00130037px;
    color: #ffffff;
  }
  &__container {
    padding: 30px;

    table {
      border-collapse: collapse;
      width: 100%;
      border: 1px solid rgba(194, 207, 224, 0.3);
      //   filter: drop-shadow(0px 6px 18px rgba(0, 0, 0, 0.06));
      box-shadow: 0px 0px 30px rgb(112 112 112 / 30%);
    }
    table thead button {
      border: none;
      background-color: unset;
    }
    th {
      font-family: Poppins;
      font-style: normal;
      font-weight: 600;
      font-size: 13px;
      line-height: 19px;
      letter-spacing: 0.01em;
      color: #707683;
      padding: 18px;
      text-align: left;
    }
    td {
      font-family: Poppins;
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
      line-height: 19px;
      letter-spacing: 0.01em;
      color: #707683;
      padding: 18px;
      text-align: left;
    }
    td:nth-child(2) {
      font-family: Poppins;
      font-style: normal;
      font-weight: 500;
      font-size: 15px;
      line-height: 22px;
      letter-spacing: 0.01em;
      color: #109cf1;
    }
    tr:nth-child(odd) {
      border: 0.5px solid #c2cfe0;
    }
    tr:nth-child(odd) {
      background-color: #f9f9f9;
    }
    i.fa.fa-sort {
      color: #7e7e7e;
    }
    .statusbutton {
      background-color: #f1f1f1bf;
      border: none;
      padding: 2px 20px;
      display: inline-block;
      width: 40%;
      margin: 10px;
      color: #e0af00;
      font-family: Poppins;
      font-style: normal;
      font-weight: 600;
      font-size: 13px;
      line-height: 19px;
      text-align: center;
      letter-spacing: 0.01em;
      border-radius: 3px;
    }
    .actions {
      margin-top: 15px;
      background-color: #e8f4fe;
      border: none;
      padding: 1px 15px 1px;
      display: flex;
      color: #2e384d;
      font-family: Poppins;
      font-style: normal;
      font-weight: 600;
      font-size: 15px;
      text-align: center;
      letter-spacing: 0.06em;
      margin-left: 25px;
      align-self: center;
      justify-content: center;
      align-content: center;
      width: fit-content;
      height: fit-content;
      padding: 15px;
      padding-bottom: 9px;
      padding-top: 0px;
    }
  }
  .nodata {
    text-align: center;
  }
  .tableRow:hover {
    cursor: pointer;
  }
}
.badge {
  width: fit-content;
  padding: 0px 10px;
}
.text_font {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 20px;
  /* identical to box height */

  text-align: center;
  letter-spacing: 0.01em;
}
