@import "../../../styles/variables.scss";

.listByFour {
  display: flex;
  overflow-x: auto;
  width: fit-content;
}
.listLimitGroup {
  width: 350px;
  box-shadow: 1px 1px 10px rgba(215, 217, 218, 0.45);
  background: #fff;
  margin: 10px;
  border-radius: 10px;
  margin-right: 20px;
  height: fit-content;
}
.listItem {
  padding: 15px;
  border-bottom: 1px solid #ebeff2;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.listImg {
  width: 5em;
  height: 5em;
  border-radius: 5px;
  img {
    width: 100%;
    height: 100%;
    border-radius: 10px;
  }
}
.listContainer {
  padding: 0 10px;
  label {
    font-family: $font-family;
    font-style: normal;
    font-weight: 500;
    font-size: 13.6052px;
    line-height: 20px;
    display: block;
    cursor: inherit;
    color: #34465e;
  }
  span {
    font-family: $font-family;
    font-style: normal;
    font-weight: 400;
    font-size: 9.35358px;
    line-height: 19px;
    /* identical to box height, or 200% */
    letter-spacing: 0.025em;

    /* Table Grey */
    color: #707683;
  }
}
.addButton {
  &:hover {
    cursor: pointer;
  }
}
.mealContainer {
  display: flex;
  align-items: center;
  width: 100%;
  &.hover {
    cursor: pointer;
  }
}

/* The snackbar - position it at the bottom and in the middle of the screen */
#snackbar {
  visibility: hidden; /* Hidden by default. Visible on click */
  min-width: 250px; /* Set a default minimum width */
  margin-left: -125px; /* Divide value of min-width by 2 */
  background-color: #333; /* Black background color */
  color: #fff; /* White text color */
  text-align: center; /* Centered text */
  border-radius: 2px; /* Rounded borders */
  padding: 16px; /* Padding */
  position: fixed; /* Sit on top of the screen */
  z-index: 1; /* Add a z-index if needed */
  left: 50%; /* Center the snackbar */
  bottom: 30px; /* 30px from the bottom */
}

/* Show the snackbar when clicking on a button (class added with JavaScript) */
#snackbar.show {
  visibility: visible; /* Show the snackbar */
  /* Add animation: Take 0.5 seconds to fade in and out the snackbar.
  However, delay the fade out process for 2.5 seconds */
  -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
  animation: fadein 0.5s, fadeout 0.5s 2.5s;
}

/* Animations to fade the snackbar in and out */
@-webkit-keyframes fadein {
  from {
    bottom: 0;
    opacity: 0;
  }
  to {
    bottom: 30px;
    opacity: 1;
  }
}

@keyframes fadein {
  from {
    bottom: 0;
    opacity: 0;
  }
  to {
    bottom: 30px;
    opacity: 1;
  }
}

@-webkit-keyframes fadeout {
  from {
    bottom: 30px;
    opacity: 1;
  }
  to {
    bottom: 0;
    opacity: 0;
  }
}

@keyframes fadeout {
  from {
    bottom: 30px;
    opacity: 1;
  }
  to {
    bottom: 0;
    opacity: 0;
  }
}
