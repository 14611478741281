$small: 300px;
$medium: 900px;
.customModal {
  // z-index: 9999 !important;
  .MuiBox-root {
    padding: 25px;
    border: 0px !important;
    border-radius: 15px;
    overflow-y: "auto";
    height: fit-content;
    max-width: 70%;
    $small: 300px;
    $medium: 900px;
    .customModal {
      z-index: 9999 !important;
      .MuiBox-root {
        padding: 25px;
        border: 0px !important;
        border-radius: 15px;
        overflow-y: "auto";
        height: fit-content;
        max-width: 70%;
        /* max-height: 90%; */
        max-height: 90vh;

        margin: 2vh 0;
        @media screen and (max-width: $small) {
          //do Smth
          width: 100%;
        }
        @media screen and (min-width: $medium) {
          //do Smth
          width: fit-content;
        }
      }
    }

    max-height: 90vh;
    margin: 2vh 0;
    @media screen and (max-width: $small) {
      $small: 300px;
      $medium: 900px;
      .customModal {
        // z-index: 9999 !important;
        .MuiBox-root {
          padding: 25px;
          border: 0px !important;
          border-radius: 15px;
          overflow-y: "auto";
          height: fit-content;
          max-width: 70%;
          /* max-height: 90%; */
          max-height: 90vh;

          margin: 2vh 0;
          @media screen and (max-width: $small) {
            //do Smth
            width: 100%;
          }
          @media screen and (min-width: $medium) {
            //do Smth
            width: fit-content;
          }
        }
      }
      width: 100%;
    }
    @media screen and (min-width: $medium) {
      $small: 300px;
      $medium: 900px;
      .customModal {
        z-index: 9999 !important;
        .MuiBox-root {
          padding: 25px;
          border: 0px !important;
          border-radius: 15px;
          overflow-y: "auto";
          height: fit-content;
          max-width: 70%;
          /* max-height: 90%; */
          max-height: 90vh;

          margin: 2vh 0;
          @media screen and (max-width: $small) {
            //do Smth
            width: 100%;
          }
          @media screen and (min-width: $medium) {
            //do Smth
            width: fit-content;
          }
        }
      }
      width: fit-content;
    }
  }
}
