.package {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  overflow-x: auto;
}

@keyframes slidein {
  from {
    margin-top: 100%;
    height: 473px;
  }

  to {
    margin-top: 0%;
    height: 473px;
  }
}

.upperPart {
  animation: slideup ease 0.5s;
}

@keyframes slideup {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }

  from {
    margin-top: -15%;
  }

  to {
    margin-top: 0%;
  }
}

.packageItem {
  display: flex;
  background-color: white;
  flex-direction: column;
  width: 200px;
  height: 236px;
  border: 1px dashed rgba(16, 156, 241, 0.4);
  border-radius: 10px;
  padding: 20px;
  margin: 20px;
  cursor: pointer;
}

.inputPackageBlock:active {
  outline: none;
  border: transparent;
}

.inputPackageBlock {
  width: 95%;
  padding-left: 40px;
  /* box-shadow: 0px 1px 5px rgb(0 0 0 / 25%); */
  background: #ffffff;
  border: 1px solid rgba(194, 207, 224, 0.3);
  box-sizing: border-box;
  box-shadow: 0px 0px 10px rgb(0 0 0 / 10%);
  border-radius: 0px 0px 10px 10px;
  height: 143%;
  /* margin-top: 0px; */
  padding-top: 14px;
  padding-bottom: 8px;
  position: relative;
  top: -22px;
  outline: none;
}

.customizeBlock {
  width: 182px;
  height: 220px;
  border: 1px dashed rgba(16, 156, 241, 0.4);
  border-radius: 10px;
  background-color: white;
  padding: 20px;
  margin: 20px;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-shrink: 0;
}

.packageBlock {
  background: rgba(247, 255, 250, 0.5);
  backdrop-filter: blur(30px);
  padding: 10px;
  width: 100%;
  height: 420px;
  display: flex;
  flex-direction: column;
}

.blocks {
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
  animation-duration: 0.5s;
  animation-name: slidein;
}

.blocks::-webkit-scrollbar {
  width: 5px;
  height: 7px;
}

.blocks::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
}

.blocks::-webkit-scrollbar-thumb {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  border-radius: 5px;
}
.customizeButton {
  width: 77px;
  height: 77px;
  border-radius: 50%;
  background: #f2f9ff;
  /* Light Blue */

  border: 1px solid #109cf1;
  color: #109cf1;
}

.itemPrice {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 26px;
  line-height: 30px;
  /* identical to box height */

  letter-spacing: 0.01em;

  /* Green */

  color: #06ab8d;
}

.itemNumber {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */

  letter-spacing: 0.01em;
  text-align: left;
  /* Dark Grey */
  padding-top: 4px;

  color: #34465e;
}

.itemDuration {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 9px;
  line-height: 14px;
  padding-top: 4px;
  /* identical to box height */

  letter-spacing: 0.01em;

  /* Green */

  color: #06ab8d;
}

.customize {
  background: #109cf1;
  /* Light Blue */

  border: 1px solid #109cf1;
  box-sizing: border-box;
  border-radius: 15px;
  width: 99px;
  height: 27px;
  color: white;
}

.getItem {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  border: 1px solid #c2cfe0;
  margin-left: 7px;
}

.getItems {
  display: flex;
  flex-direction: row;
  padding: 4px;
  /* float: left; */
  position: relative;
  /* margin-right: 10px; */
  left: -10px;
}

.customizeButtonName {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  transition-duration: 1s;
  /* identical to box height */
  letter-spacing: 0.01em;
}

.packageItem.active {
  border: 2px solid #109cf1;
  margin-top: 0.5px;
  transition-duration: 1s;
}

.lensImage {
  position: absolute;
  left: 24px;
  top: 7px;
}

.tabs.package {
  display: flex;
  align-items: center;
}

.tabItem {
  margin-left: 10px;
  padding: 16px;
}

.tabItemButton {
  outline: none;
  border: none;
  background-color: rgba(247, 255, 250, 0.5);
}

.tabItemButtonDefault {
  outline: none;
  border: none;
  background-color: rgba(247, 255, 250, 0.5);
  color: #109cf1;
  border-bottom: 2px solid #109cf1;
}

.tabItemButton:focus {
  color: #109cf1;
  border-bottom: 2px solid #109cf1;
}

.priceChildClass {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 15px;
  letter-spacing: 0.01em;
  color: #06ab8d;
}
.round {
  position: relative;
}

.round label {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 50%;
  cursor: pointer;
  height: 28px;
  left: 0;
  position: absolute;
  top: 0;
  width: 28px;
}

.round label:after {
  border: 2px solid #fff;
  border-top: none;
  border-right: none;
  content: "";
  height: 6px;
  left: 7px;
  opacity: 0;
  position: absolute;
  top: 8px;
  transform: rotate(-45deg);
  width: 12px;
}

.round input[type="checkbox"] {
  visibility: hidden;
}

.round input[type="checkbox"]:checked + label {
  background-color: #109cf1;
  border-color: #109cf1;
}

.round input[type="checkbox"]:checked + label:after {
  opacity: 1;
}
.packageItemTop {
  display: flex;
  gap: 60px;
}
.itemDescription {
  font-size: 12px;
  overflow: hidden;
  text-align: left;
  text-overflow: ellipsis;
  padding: 0;
  margin: 0;
  position: relative;
  display: -webkit-box;
  -webkit-line-clamp: 7;
  -webkit-box-orient: vertical;
}
.closePackage {
  position: relative;
  top: -15px;
  left: 5px;
}
