@import "../../styles/variables.scss";
.MenuContainer {
  font-family: "Poppins";
  font-style: normal;
  position: relative;
  display: flex;
  justify-content: center;
  .FilterMenuTriggerActive {
    background: #ffffff;
    border: 3px solid #109cf1;
    border-radius: 5px;
    color: #109cf1;
    height: 40px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 40px;
  }
  .FilterMenutrigger {
    background: #ffffff;
    border: 1.5px solid #109cf1;
    border-radius: 5px;
    color: #109cf1;
    height: 40px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 40px;
  }

  &__span {
    font-family: $font-medium;
    font-weight: 600;

    justify-content: space-around;
    padding-left: 5px;
  }

  .icon {
    margin: 7px;
  }

  .menu {
    background: #ffffff;
    border-radius: 8px;
    position: absolute;
    top: 60px;
    right: 0;
    width: 220px;
    // height: 270px;
    // overflow-x: hidden;
    z-index: 1;
    // overflow-y: scroll;
    box-shadow: 0 1px 8px #90a0b7;
    opacity: 0;
    visibility: hidden;
  }

  .menu.active {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
  }

  .heading {
    //  margin: 15%;
    // padding-top: 1px;
    padding-bottom: 5px;
    border-bottom: 0.1px ridge #90a0b7;
  }

  &__content {
    padding-left: 5px;
    margin-bottom: 1px;
    font-size: 13px;
    color: #707683;
    font-weight: 500;
  }
  input {
    margin: 0.7rem;
  }
  .applyfilters {
    background: #109cf1;
    border: 1px solid #109cf1;
    border-radius: 5px;
    color: white;
    width: 91.5%;
    height: 40px;
    cursor: pointer;
    display: center;
    justify-content: space-between;
    align-items: center;
    margin-left: 20px;
    margin-top: -5px;
    font-weight: 500;
    vertical-align: middle;
    font-size: 14px;
    margin: 9px;
  }
}
