.exercise {
  .Addmodal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
  }
  .contentbox {
    width: 150px;
  }
  .HeaderText {
    padding-bottom: 20px;
    padding-top: 20px;
    padding-left: 30px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.025em;
  }
  .AddPackage-main {
    position: relative;
    background: white;
    max-width: 900px;
    max-height: 800px;
    top: 50%;
    left: 50%;
    border: 1px solid rgba(194, 207, 224, 0.3);
    box-shadow: 0px 0px 30px rgb(112 112 112 / 30%);
    border-radius: 10px;
    transform: translate(-50%, -50%);
    overflow: scroll;
  }
  .videoPackage {
    margin: 0px;
  }
  .formstyle {
    padding: 20px;
  }
  .packagecontent {
    padding: 0px !important;
    width: 100%;
  }
  .heading {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    /* identical to box height */
    padding-left: 30px;
    padding-top: 10px;
    padding-right: 10px;
    /* Title */

    color: #202c46;
    &__image {
      cursor: pointer;
      float: right;
      margin-right: 10px;
      margin-top: 5px;
    }
  }

  .display-block {
    z-index: 1;
    display: block;
  }
  .payment-type {
    display: flex;
    gap: 5px;
  }
  .feature-type {
    display: flex;
    gap: 20px;
  }
  .display-none {
    display: none;
  }
  .noVideo {
    display: flex;
    height: 200px;
    border: 1px dotted;
    border-radius: 5px;
    justify-content: center;
    align-items: center;
    background: #aadcff57;
    cursor: pointer;
    margin-bottom: 15px;
    flex-flow: column;
  }
  .package-upload {
    cursor: pointer;
  }
  .videoChange {
    position: absolute;
    cursor: pointer;
    right: 0;
    display: flex;
    justify-content: end;
    align-items: end;
    z-index: 1;
    padding: 5px;
  }
  .videoPreview {
    border-radius: 15px;
    position: relative;
    video {
      border-radius: 15px;
    }
  }
  .savepackage {
    cursor: pointer;

    float: right;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;

    margin-bottom: 25px;

    margin-right: 40px;
    color: #ffff;
    width: 140px;
    height: 35px;
    background-color: rgb(16, 156, 241);
    border: 1px solid rgb(16, 156, 241);
    border-radius: 5px;
  }

  .inputType {
    width: 120px;

    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.025em;
  }

  .contentalign {
    display: flex;
  }
  .contentinputBox {
    input {
      width: 100%;
    }

    // border: 1px solid #c2cfe0;
    // box-sizing: border-box;
    // height: 40px !important;
    // width: 305px !important;
  }
  .contenttextarea {
    border: 1px solid #c2cfe0;
    box-sizing: border-box;
    margin-left: 5px;
    height: 170% !important;
    margin-right: 5px !important;
    width: 194%;
    resize: none;
    border-radius: 5px;
  }

  .contentboxone {
    border: 1px solid #c2cfe0;
    box-sizing: border-box;
    margin-left: 5px;
    height: 25px !important;
    margin-right: 5px !important;
    width: 100px !important;
  }
  .inputContent {
    display: flex;
    height: 60px;
    div {
      width: 100% !important;
      input {
        width: 100%;
      }
    }
  }
  .packagename {
    display: flex;
    height: 60px;
  }
  .price {
    display: flex;
    height: 60px;
  }
  .description {
    display: flex;
    height: 85px;
  }
  .duration {
    display: flex;
    height: 60px;
  }
  .producttype {
    display: flex;
    height: 43px;
  }
  .customdescriptionBox {
    border: 1px solid #c2cfe0;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 5px;
    height: 200px;
    width: 305px;
    overflow: scroll;
  }
  .errorMessage {
    font-size: 12px;
    color: red;
  }
  .additionalUnits {
    padding: 10px 0;
    align-items: flex-end;
    justify-content: space-around;
    input[type="text"] {
      border: none;
      margin-right: 0;
      background-color: white;
    }
    input[type="number"] {
      border: none;
      margin-right: 0;
      height: 30px;
      background-color: white;
    }
    .kg {
      background: white;
    }
    .equal {
      display: flex;
      align-items: center;
      padding: 5px;
      color: #707683;
    }
  }
}
