@import "../../../../styles/variables.scss";
.backdrop-filter {
  position: absolute;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(10px);
  text-align: center;

  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
}

.hide-on-drag {
  display: none;
}

.drag {
  .DietPlanDetail__container__card__dietbox__dietcard:not(.blurcard) {
    .hide-on-drag {
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      backdrop-filter: blur(10px);
      text-align: center;
      padding-top: 35%;
      z-index: 2;
    }
  }
}
.cursor {
  cursor: ns-resize;
}
.mealsPlanner {
  padding: 30px;
  box-shadow: azure;

  border-radius: 12px;
}
.mealsPlannerContainer {
  border: 1px dashed #c2cfe0;
  border-radius: 10px;
}
.horizontalScrollableDiv {
  overflow-x: auto;
}
.mealsCalendar {
}
.mealsSchedule {
  border-top: 1px solid #eee;
  padding: 0;
  margin: 0;
}
.mealsTab {
  position: relative;
  min-height: 450px;
}
.mealsGradient {
  background: linear-gradient(
    180deg,
    #fffced 0%,
    rgba(255, 252, 237, 0.18) 100%
  );
  height: 30px;
}
.searchFoodListTabContainer {
  padding: 10px;
}
.searchBar {
  margin: 0 50px;
  border: 1px solid #c2cfe0;
  background: #fff;
  border-radius: 5px;
}
.searchFoodContainers {
  background: rgba(232, 244, 254, 0.8);
  -webkit-backdrop-filter: blur(30px);
  backdrop-filter: blur(30px);
  /* top: 0; */
  /* top: 0; */
  width: 90%;
  height: 90%;
  border-radius: 10px;
  margin-left: 37px;
  overflow-y: auto;
}
.searchFoodHeader {
  display: flex;
  justify-content: space-between;
  padding: 0 50px;
  align-items: center;
  h2 {
    font-family: $font-family;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    /* identical to box height, or 122% */
    letter-spacing: 0.025em;

    /* Dark Grey */
    color: #34465e;
  }
}
.AddMealHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 20px;
  h2 {
    font-family: $font-family;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 0px;
    color: #202c46;
    letter-spacing: 0.025em;
  }
}
.AddMealContainer {
  display: flex;
  .AddMeal {
    display: flex;
    flex: 1;
    flex-direction: column;
    &.image {
      width: 140px;
      height: 174px;
      padding-right: 15px;
      border-radius: 6px;
      img {
        border-radius: 10px;
      }
    }
    .mealName {
      font-family: $font-family;
      font-style: normal;
      font-weight: 500;
      font-size: 13.6052px;
      line-height: 20px;
      color: #34465e;
      padding-top: 10px;
    }
    label {
      font-family: $font-family;
      font-style: normal;
      font-weight: 400;
      font-size: 9.35358px;
      line-height: 19px;
      letter-spacing: 0.025em;
      color: #707683;
    }
  }
  .mealContainer {
    display: flex;
    flex: 2;
    flex-direction: column;
    background: #f3f6fa;
    border: 1px dashed #c2cfe0;
    border-radius: 6px;
    padding: 10px;
  }
}
.inputContainer {
  padding: 10px !important;
}
.react-datepicker-wrapper {
  .react-datepicker__input-container {
    input[type="text"] {
      width: 100%;
    }
  }
}
.react-datepicker {
  &__week {
    background: inherit !important;
  }
  &__day {
    &--disabled {
      background: inherit !important;
    }
  }
  &__in-range {
    background: #0d9cf0;
  }
}
.inline {
  display: flex;
  width: 100%;
}

.modalFooter {
  display: flex;
  justify-content: flex-end;
  padding: 10px 0;
}
.workoutEmptyBox {
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  box-shadow: 0px 0px 30px 0px #0000001a;
  border: none;
  outline: none;
  padding: 30px;

  background: #ffffff;
  box-sizing: border-box;
  border-radius: 10px;
  &__image {
    border: none;
    background: #ffffff;
  }
  margin: 10px 10px;
}
