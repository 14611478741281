@import "../../../styles/variables.scss";

.mealItem {
  display: flex;
  margin: 3px !important;
  &:hover {
    background-color: black;
  }
}
.mealServeInfo {
  padding: 0 10px;
  width: 150px;
}
.mealName {
  font-family: $font-family;
  font-style: normal;
  font-weight: 600;
  font-size: 11.6052px;
  line-height: 20px;

  cursor: pointer;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

.mealGroup {
  font-family: $font-family;
  font-style: normal;
  font-weight: 400;
  font-size: 9.35358px;
  line-height: 19px;
  color: #707683;
  letter-spacing: 0.025em;
}
.mealImg {
  width: 6em;
  height: 6em;
  img {
    width: 100%;
    height: 100%;
    border-radius: 10px;
  }
}
.mealsServings {
  color: #34465e;
  font-family: $font-family;
  font-style: normal;
  font-weight: 500;
  font-size: 10.2039px;
  line-height: 17px;
  padding: 10px 0;
  /* or 167% */
  letter-spacing: 0.0225em;
}

// .clicked {
// }

.deleteServing {
  display: none;
}
.mealItem {
  padding: 5px;
  position: relative;
  .mealDesc {
    &:hover {
      cursor: pointer;
    }
  }
  &:hover {
    background: #f2f9ff;
    box-shadow: 0px 1.70065px 6.8026px rgba(0, 0, 0, 0.1);
    .deleteServing:last-of-type {
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      &:hover {
        cursor: pointer;
      }
    }
  }
  .deleteServing:last-of-type {
    margin-left: auto;
    padding: 6px 8px 8px 0px;
  }

  .clicked {
    background: #f2f9ff;
    box-shadow: 0px 1.70065px 6.8026px rgba(0, 0, 0, 0.1);
  }
}
