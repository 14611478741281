@import "../../../styles/variables.scss";

.mealInfo {
  display: flex;

  align-items: center;
  padding: 10px 0;
}
.mealInfoHeading {
  display: flex;
  justify-content: center;
  align-items: center;
}
.mealType {
  padding: 0 10px;
}
.mealTypeWithNutients {
  display: flex;
}
.mealInfoHeading h2 {
  font-family: $font-family;
  font-size: 18px;
  font-weight: 600;
  line-height: 12px;
  letter-spacing: 0.025em;
  text-align: left;
  color: #34465e;
  margin: 0;
}
.mealInfoHeading label {
  font-family: $semi-bold;
  font-size: 10px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0.025em;
  text-align: right;
  color: #34465e;
}

.mealInfoAddButton > .searchFood {
  background: #ffffff;
  /* Grey */
  border: 1px solid #c2cfe0;
  box-sizing: border-box;
  border-radius: 5px;
  color: #c2cfe0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 10px;
  cursor: pointer;
}
.mealInfo {
}
.mealInfoHeading {
}
