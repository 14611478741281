.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 320px;
  background-color: #fff;
  box-shadow: 6px 0px 18px rgba(0, 0, 0, 0.06);
  z-index: 1;
  &__profile {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 15px 5px 15px 30px;
    box-shadow: 6px 0px 18px rgba(0, 0, 0, 0.06);
    margin-bottom: 30px;
    & > div.profile {
      margin: 0 10px;
      overflow: hidden;
    }
    & div.username {
      color: #192a3e;
      font-size: 14px;
      line-height: 21px;
      text-overflow: ellipsis;
      overflow: hidden;
      font-weight: bold;
    }
    & div.email {
      color: #90a0b7;
      font-size: 14px;
      line-height: 21px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      max-height: 42px !important;
    }

    .email .tooltiptext {
      visibility: hidden;
      background-color: black;
      color: #109cf1;
      text-align: center;
      border-radius: 6px;
      padding: 15px 10px;
      position: absolute;
      text-indent: 1;
      z-index: 1;
      left: 100px;
      top: 15%;
      transition: color 0.3s ease-in-out;
    }
    .email:hover .tooltiptext {
      visibility: visible;
      cursor: pointer;
    }

    & div.signOut {
      font-size: 12px;
      color: rgb(240, 131, 131);
      cursor: pointer;
      width: fit-content;
    }
    & div.signOut {
      font-size: 12px;
      color: rgb(240, 131, 131);
    }
  }
  &__logo {
    display: grid;
    place-items: center;
    height: 60px;
    font-size: 1.5rem;
    font-weight: 700;
    font-family: "Mochiy Pop P one", sans-serif;
  }

  &__menu {
    position: relative;

    &__item {
      display: flex;
      align-items: center;
      place-content: flex-start;
      padding: 0.3rem 2rem;
      font-size: 1rem;
      font-weight: 500;
      color: #34465e;
      transition: color 0.3s ease-in-out;
      &__icon {
        margin-right: 1rem;
        width: 15px;
        i {
          font-size: 1.75rem;
        }
      }
      &.active {
        color: #109cf1;
        .icon {
          i {
            svg {
              path,
              rect {
                stroke: #109cf1;
              }
            }
          }
        }
      }
    }

    &__indicator {
      position: absolute;
      top: 0;
      left: 50%;
      width: calc(100% - 30px);
      border-radius: 10px;
      background-color: #f3f6fa;
      z-index: -1;
      transform: translateX(-50%);
      transition: 0.3s ease-in-out;
    }
  }
  .AddProfile {
    display: flex;
    flex: 1;
    flex-direction: column;
    &.image {
      width: 40px;
      img {
        border-radius: 50px;
        height: 50px;
      }
    }
  }
  .imageBox {
    position: relative;
    float: left;

    &:hover {
      opacity: 0.4;
    }
    svg {
      width: 20px;
      height: 20px;
    }
  }

  .imageBox .hoverImg {
    position: absolute;
    left: 30%;
    top: 25%;
    right: 50%;
    bottom: 50%;
    width: 50%;
    display: none;
  }

  .imageBox:hover .hoverImg {
    display: flex;
  }
}
