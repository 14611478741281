.workoutNotes {
  p {
    font-family: "Reenie Beanie";
    font-size: 1rem;
    font-style: italic;
    box-shadow: none;
    outline: none;
    line-height: 18px;
    height: 100%;
    overflow: auto;
  }
  .pin {
    background-color: #aaa;
    display: block;
    height: 32px;
    width: 2px;
    position: absolute;
    left: 50%;
    top: -16px;
    z-index: 1;
  }

  .pin:after {
    background-color: #a31;
    background-image: radial-gradient(
      25% 25%,
      circle,
      hsla(0, 0%, 100%, 0.3),
      hsla(0, 0%, 0%, 0.3)
    );
    border-radius: 50%;
    box-shadow: inset 0 0 0 1px hsla(0, 0%, 0%, 0.1),
      inset 3px 3px 3px hsla(0, 0%, 100%, 0.2),
      inset -3px -3px 3px hsla(0, 0%, 0%, 0.2),
      23px 20px 3px hsla(0, 0%, 0%, 0.15);
    content: "";
    height: 12px;
    left: -5px;
    position: absolute;
    top: -10px;
    width: 12px;
  }

  .pin:before {
    background-color: hsla(0, 0%, 0%, 0.1);
    box-shadow: 0 0 0.25em hsla(0, 0%, 0%, 0.1);
    content: "";

    height: 24px;
    width: 2px;
    left: 0;
    position: absolute;
    top: 8px;

    transform: rotate(57.5deg);
    -moz-transform: rotate(57.5deg);
    -webkit-transform: rotate(57.5deg);
    -o-transform: rotate(57.5deg);
    -ms-transform: rotate(57.5deg);

    transform-origin: 50% 100%;
    -moz-transform-origin: 50% 100%;
    -webkit-transform-origin: 50% 100%;
    -ms-transform-origin: 50% 100%;
    -o-transform-origin: 50% 100%;
  }
}
div.workoutNotes {
  position: absolute;
  border-radius: 0;
  text-decoration: none;
  color: #000;
  background: #ffc;
  display: block;
  z-index: 3;
  height: 6em;
  width: 13em;
  padding: 5px;
  left: 90px;
  box-shadow: 5px 5px 7px rgba(33, 33, 33, 0.7);
  transform: rotate(-6deg);
  transition: transform 0.15s linear;
  &:hover {
    transform: scale(
      1.5
    ); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
  }
}
