// @import url("https://fonts.googleapis.com/css?family=Poppins:400,700,900");
a {
  color: #2b6cb0;
}
.row {
  display: flex;
  width: 100%;
  height: 100vh;
}
.fitness {
  float: left;
}
.fitness.img {
  display: flex;
  flex: 1.55 1;
  background-color: #3e54a594;
  background-image: url("../../images/loginbg.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.fitness.logo {
  padding-left: 30px;
  padding-top: 25px;
}
.fitness_image {
  width: 100%;
  height: 100%;
}
.fitness.form {
  display: flex;
  flex: 1;
  flex-direction: column;
  background: white;
  box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  overflow-y: hidden;
}
.fitness.form h6 {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #2d3748;
  margin-top: 120px;
  margin-left: 72px;
  margin-bottom: 21px;
}
.welcome_text {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;

  padding-top: 100px;

  color: #2d3748;
  margin-top: 130px;
  margin-left: 72px;
}
.login_head {
  flex-basis: 40%;
}
.login_text {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  color: #1a202c;
  margin-left: 72px;
}
.fitness.form h3 {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  color: #1a202c;
  margin-left: 72px;
  margin-top: 12px;
}
.row:after {
  content: "";
  display: table;
  clear: both;
}
input[type="text"],
input[type="password"] {
  height: 38px;
  border: 1px solid #c2cfe0;
  box-sizing: border-box;
  border-radius: 5px;
  padding-left: 5px;
}
.stylebutton {
  cursor: pointer;
  background: #109cf1;
  border-radius: 5px;
  border: 1px solid #109cf1;
  color: #fff;
  padding: 10px 20px;
  width: 74%;
  border-radius: 5px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  margin-left: 25px;
  background-color: rgb(16, 156, 241);
  border: 1px solid rgb(16, 156, 241);
  border-radius: 5px;
  height: 40px;
  width: 75%;
}
.input-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
  position: relative;
  margin: 10px;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  margin-left: 14px;
  margin-left: 73px;
}
.input-container input[type="password"] {
  padding-left: 35px !important;
  display: inline-block;
  width: 100;

  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */

  /* 800 */

  color: #2d3748;
}
.input-container input[type="text"] {
  padding-left: 35px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */

  /* 800 */

  color: #2d3748;
}
.input-container svg {
  width: 30px;
  position: absolute;
  height: 22px;
  right: 10px;
  top: 48px;
}
.inputFront svg {
  width: 30px;
  position: absolute;
  height: 22px;

  left: 7px;
  top: 49px;
}

.button-container {
  display: flex;
  justify-content: center;
  margin-top: 10px;
  padding-bottom: 30px;
}
.message {
  display: flex;
  justify-content: space-between;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  color: #2d3748;
  margin-left: 72px;
  margin-top: 20px;
  margin-right: 50px;
}
.bottom {
  border-top: 1px solid #e8e8e8;
}
.bottom p {
  text-align: center;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
}
.loginbtn {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  margin: 12px 52px 0px 68px;
  border: 1px solid #109cf1;
  background-color: #109cf1;
  height: 40px;
  cursor: pointer;
  border-radius: 5px;
  width: 100%;
}

.errors {
  color: red;
  &__in-signin {
    color: red;
    margin-left: 72px;
  }
}

.forgot-container {
  margin-left: 73px;
}
/* Responsive layout - makes the three columns stack on top of each other instead of next to each other */

@media only screen and (max-width: 768px) {
  .welcome_text {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    padding-top: 36px;
    color: #2d3748;
    margin-top: 0px;
    margin-left: 72px;
  }
  .fitness.img {
    display: flex;
    display: none;
    flex: 1.55 1;
    background-color: #3e54a594;
  }
}
.signin__button {
  cursor: pointer;
}
/* Small Devices, Tablets */
@media only screen and (min-width: 768px) and (max-width: 992px) {
  .welcome_text {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    padding-top: 36px;
    color: #2d3748;
    margin-top: 0px;
    margin-left: 72px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .welcome_text {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    padding-top: 36px;
    color: #2d3748;
    margin-top: 0px;
    margin-left: 72px;
  }
}
