@import "../../styles/variables.scss";
.clients {
  &__header {
    display: flex;
    padding: 30px 30px 0 30px;
    justify-content: space-between;
    h1 {
      color: #000000;
      font-size: 26px;
      font-weight: 500;
      font-family: $font-medium;
      line-height: 39px;
      font-style: normal;
      flex: 2;
    }
    &__content {
      display: flex;
      align-items: center;
    }
  }
  .filterbtn {
    font-family: $font-family;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 19px;
    letter-spacing: 0.00130037px;
    color: #109cf1;
  }
  .createnewbtn {
    font-family: $font-family;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 19px;
    letter-spacing: 0.00130037px;
    color: #ffffff;
    flex-shrink: 0;
    margin: 0 5px;
  }
  &__container {
    padding: 30px;
  }

  table {
    border-collapse: collapse;
    width: 100%;
    border: 1px solid rgba(194, 207, 224, 0.3);
    //   filter: drop-shadow(0px 6px 18px rgba(0, 0, 0, 0.06));
    box-shadow: 0px 0px 30px rgb(112 112 112 / 30%);
  }
  table thead button {
    border: none;
    background-color: unset;
  }
  th {
    font-family: $font-family;
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 19px;
    letter-spacing: 0.01em;
    color: #707683;
    padding: 12px;
    text-align: left;
  }
  td {
    font-family: $font-family;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 19px;
    letter-spacing: 0.01em;
    color: #707683;
    padding: 12px;
    text-align: left;
    span {
      display: flex;
      align-items: center;
    }
  }
  td:nth-child(1) {
    font-family: $font-family;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
    letter-spacing: 0.01em;
    color: #109cf1;
  }
  tr:nth-child(odd) {
    border: 0.5px solid #c2cfe0;
  }
  tr:nth-child(odd) {
    background-color: #f9f9f9;
  }
  i.fa.fa-sort {
    color: #7e7e7e;
  }
  .memerbshipbutton {
    background-color: #f1f1f1bf;
    border: none;
    padding: 7px 30px;
    margin: 10px;
    color: #e0af00;
    font-family: $font-family;
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 19px;
    text-align: center;
    letter-spacing: 0.01em;
    border-radius: 3px;
  }
  .actions {
    margin-top: 15px;
    background-color: #e8f4fe;
    border: none;
    padding: 0px 5px 0px 5px;
    display: inline-block;
    color: #2e384d;
    font-family: $font-family;
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    text-align: center;
    letter-spacing: 0.01em;
    margin-left: 25px;
  }
  .nodata {
    text-align: center;
  }
  .badge {
    width: fit-content;
    padding: 0px 10px;
  }
  .tableRow:hover {
    cursor: pointer;
  }
  .text_font {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 20px;
    /* identical to box height */

    text-align: center;
    letter-spacing: 0.01em;
  }
}
