@font-face {
  font-family: Poppins;
  src: url(fonts/Poppins-Regular.ttf);
}
@font-face {
  font-family: Poppins-SemiBold;
  src: url(fonts/Poppins-SemiBold.ttf);
}
@font-face {
  font-family: Poppins-Medium;
  src: url(fonts/Poppins-Medium.ttf);
}
// @import url(fonts/Poppins.css);
$font-family: "Poppins";
$semi-bold: "Poppins-SemiBold";
$font-medium: "Poppins-Medium";
$blue: #109cf1;
$dark-blue: #2b6cb0;
$dark-grey: #34465e;
$grey: #90a0b7;
$black: #000000;
$table-grey: #707683;
$dark-grey: #2d3748;
$white: #fff;
