.newbt {
  display: flex;
  align-items: center;
}
.layoutnameslist {
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  display: block !important;
  font-size: 15px;
  line-height: 22px;
  width: 400px !important;
  overflow: hidden !important;
  letter-spacing: 0.01em;
  color: #109cf1;
  overflow: hidden;
  text-overflow: ellipsis !important;
}
.layoutname {
  white-space: nowrap;
}
