@import "../../../../styles/variables.scss";

.servings {
  .qty {
    max-width: 30px;
    height: 30px;
    margin-right: 5px;
  }
  .unit {
    height: 30px;
  }
  p {
    display: flex;
    font-size: 12px;
  }
  .label {
    font-size: 12px;
    font-weight: 500;
  }
  .row-lr {
    display: flex !important;
    justify-content: space-between !important;
  }

  .inputContainer {
    padding: 0 10px 10px 0px !important ;
  }
}
