@import "../../styles/variables.scss";
.backtoclients {
  content: "\003C";
  background: #ffffff;
  align-items: center;
  border: none;
  margin-left: 10px;
  font-family: $font-medium;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.00140039px;
  color: #1c78ba;
}
.backtoclients:hover {
  cursor: pointer;
}
.activetab {
  display: block !important;
}
.inactivetab {
  display: none !important;
}
.activeunderline {
  color: $blue;
  border-bottom: 3px solid $blue;
}

.clientdetails {
  &__header {
    display: flex;
    height: 118px;
    padding: 0px 25px;
    align-items: center;
    &__text {
      flex: 1;
      color: #000000;
      font-size: 26px;
      font-weight: 500;
      font-family: $font-family;
      font-style: normal;
      line-height: 39px;
    }
  }
  .filterbtn {
    font-family: $font-medium;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 19px;
    letter-spacing: 0.00130037px;
    color: $blue;
  }
  .createnewbtn {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: $font-family;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 19px;
    letter-spacing: 0.00130037px;
    color: #ffffff;
  }
  &__container {
    margin-left: 35px;
    height: 100%;
    background: #ffffff;
    /* Opacity Grey */
    margin-right: 27px;
    padding: 0px 0px 0 0px;
    border: 1px solid rgba(194, 207, 224, 0.3);
    box-sizing: border-box;
    border-radius: 10px;
    &__header {
      font-weight: 500;
      height: 67px;
      background: #ffffff;
      display: flex;
      cursor: pointer;
      &__left {
        font-weight: 500;
        height: 67px;
        background: #ffffff;
        display: flex;
        cursor: pointer;
        flex: 1 1;
      }
      &__right {
        color: #06ab8d;

        display: flex;
        align-items: center;
        padding-right: 10px;
        &__image {
          padding-top: 10px;

          padding-right: 5px;
        }
        &__text {
          padding-left: 10px;
        }
      }
      &__item {
        display: flex;
        align-items: center;
        place-content: flex-start;
        padding: 27px;

        &__text {
          font-family: $semi-bold;
          font-size: 14px;
          font-weight: 600;
          color: #34465e;
          transition: color 0.3s ease-in-out;
        }
      }
    }
    &__profile {
      height: 237px;

      background: #f2f9ff;
      /* Opacity Grey */

      border: 1px solid rgba(194, 207, 224, 0.3);
      box-sizing: border-box;
      display: flex;

      &__left {
        flex: 1 1;
        padding: 30px 50px 20px 50px;
        width: 75%;
        &__content {
          &__profile {
            display: flex;
            padding-right: 30px;
            padding-top: 25px;
            padding-bottom: 20px;

            justify-content: space-between;

            &__name {
              flex: 1 1;
              display: flex;
              align-items: center;
              &__username {
                font-family: $semi-bold;
                display: flex;
                flex-direction: column;
                margin-left: 10px;
              }
              &__image {
                margin-top: 5px;
              }
              &__health {
                display: flex;
                &__goal {
                  display: flex;
                  flex-direction: column;
                  padding-left: 10px;
                  padding-right: 10px;
                  &__color {
                    font-family: $font-medium;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 24px;
                    /* identical to box height */

                    text-align: right;
                    letter-spacing: 0.01em;

                    /* Green */

                    color: #06ab8d;
                  }
                }
                &__image {
                  padding-top: 5px;
                }
              }
            }
          }
        }
        &__center {
          display: flex;
          justify-content: space-between;
          align-items: center;
          height: 80px;
          // margin-left: 15px;
          // margin-right: 15px;
          padding: 10px;

          padding: 5px 60px 10px 50px;

          background: #ffffff;
          /* Opacity Grey */

          border: 1px solid rgba(194, 207, 224, 0.3);
          box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
          border-radius: 5px;

          &__period {
            display: flex;
            &__date {
              font-size: 14px;
              font-family: "Poppins-SemiBold";
              font-weight: 600;
              color: $dark-grey;
            }
            &__filter {
              margin-top: 3px;
              padding-right: 5px;

              &__date {
                padding-left: 20px;
                font-family: $semi-bold;
                font-size: 14px;
              }
            }
          }
          &__member {
            display: flex;

            &__filter {
              padding-left: 5px;
              height: 21px;

              &__status {
                font-family: $semi-bold;
                padding-left: 5px;
                font-size: 14px;
                color: #06ab8d;
              }
            }
          }
          &__payment {
            display: flex;

            &__filter {
              padding-right: 5px;
              height: 21px;
              font-size: 14px;
              font-family: "Poppins-SemiBold";
              font-weight: 600;
              color: $dark-grey;
              &__paid {
                padding-left: 5px;
                font-size: 14px;
                font-family: $semi-bold;
              }
            }
          }
        }
      }
      &__right {
        background: rgba(247, 255, 250, 0.8);
        width: 25%;
        height: 237px;
        border: 1px solid rgba(194, 207, 224, 0.3);
        box-sizing: border-box;
        position: relative;
        padding: 30px;
        display: flex;
        flex-direction: column;
        &__item {
          display: flex;
          align-items: center;
          place-content: flex-start;
          padding: 10px;
          &__icon {
            margin-right: 1rem;
            width: 15px;
            &__text {
              padding-bottom: 5px;
              font-family: $font-medium;
              font-weight: 500;
              font-size: 14px;
            }
          }
        }
      }
    }
    &__meals {
      height: 550px;
      padding: 30px 50px;
      background: linear-gradient(
        180deg,
        #f2f9ff 0%,
        rgba(242, 249, 255, 0.18) 100%
      );
      &__nutriinfo {
        padding: 32px 33px 30px 42px;
        height: 475px;
        background: #ffffff;
        /* Shadow */

        box-shadow: 0px 0px 30px rgba(190, 204, 223, 0.3);
        border-radius: 12px;
        &__header {
          display: flex;
          &__text {
            margin-left: 10px;
          }
          &__line {
            margin-left: -35px;
          }
        }
      }

      &__box1 {
        padding: 32px 33px 30px 42px;
        margin-top: -25.1rem;
        &__header1 {
          margin-top: -15rem;
          margin-left: 10rem;
          color: #34465e;
          font-size: 15px;
          &__dragger1 {
            margin-top: -1rem;
            &__content1 {
              font-family: "Poppins";
              font-style: normal;
              color: #707683;
              font-weight: 400;
              font-size: 10px;
              line-height: 15px;
            }
          }
        }
        &__header2 {
          margin-top: 2rem;
          margin-left: 10rem;
          color: #34465e;
          font-size: 15px;
          &__dragger2 {
            margin-top: -1rem;
            &__content2 {
              font-family: "Poppins";
              font-style: normal;
              color: #707683;
              font-weight: 400;
              font-size: 10px;
              line-height: 15px;
              position: absolute;
            }
          }
        }
      }
      &__box2 {
        padding: 32px 33px 30px 42px;
        margin-top: -21rem;
        font-weight: 500;
        &__bmr {
          margin-top: -5.5rem;
          margin-left: 2rem;
          color: #707683;
          font-size: 12px;
          &__info1 {
            margin-top: -1.8rem;
            margin-left: 4.5rem;
          }
          &__bmrcount {
            color: #34465e;
            margin-top: 7px;
            font-size: 14px;
          }
        }
        &__weightgoal {
          margin-top: -3.8rem;
          margin-left: 13rem;
          color: #707683;
          font-weight: 500;
          font-size: 12px;
          &__info2 {
            margin-top: -1.9rem;
            margin-left: 5.5rem;
          }
          &__weightgoalcount {
            margin-top: 4.3px;
            color: #34465e;
            background-color: #ffffff;
            border: none;
            height: 29px;
            width: 80px;
            box-shadow: 5px 10px 20px rgba(0, 0, 0, 0.25) inset;
            offset: 0px, 4px;
            font-size: 14px;
            font-weight: 600;
            border-radius: 6px;

            &__minus1 {
              margin-top: -23px;
              margin-left: 5.7rem;
            }
            &__plus1 {
              margin-top: -24px;
              margin-left: 7rem;
            }
          }
        }
        &__lbs {
          margin-top: -4rem;
          margin-left: 24.7rem;
          color: #707683;
          font-size: 12px;
          font-weight: 500;
          &__info3 {
            margin-top: -1.8rem;
            margin-left: 5.5rem;
          }
          &__lbscount {
            margin-top: 4.3px;
            color: #34465e;
            background-color: #ffffff;
            border: none;
            height: 29px;
            width: 80px;
            box-shadow: 5px 10px 20px rgba(0, 0, 0, 0.25) inset;
            offset: 0px, 4px;
            font-size: 14px;
            font-weight: 600;
            border-radius: 6px;
            &__minus2 {
              margin-top: -23px;
              margin-left: 5.7rem;
            }
            &__plus2 {
              margin-top: -24px;
              margin-left: 7rem;
            }
          }
        }
      }

      &__box3 {
        margin-top: 1rem;
        margin-left: 4rem;
        &__run {
          margin-top: -9.5rem;
          margin-left: 5.5rem;
          &__pal {
            color: #707683;
            font-size: 15px;
            &__info4 {
              margin-top: -2.4rem;
              margin-left: 2rem;
            }
            &__count {
              color: #2d3748;
              margin-top: -2px;
              font-size: 22px;
            }
          }
        }
      }

      &__box4 {
        padding: 3px 3px 3px 6px;
        margin-top: -18.5rem;
        margin-left: 39rem;
        &__header1 {
          color: #2d3748;
          margin-top: -320px;
          margin-left: 1.5rem;
          font-size: 17px;
          &__plus3 {
            margin-top: -1.5rem;
            margin-left: 17rem;
          }
        }
        &__header2 {
          color: #2d3748;
          margin-top: 130px;
          margin-left: 1.5rem;
          font-size: 17px;
          &__plus4 {
            margin-top: -1.5rem;
            margin-left: 17rem;
          }
        }
      }
    }
  }
}

.text {
  font-family: $font-medium;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  /* identical to box height */
  opacity: 0.8;
  letter-spacing: 0.01em;

  /* Table Grey */

  color: #707683;
}
.noMembership {
  height: 80px;
  width: 100%;
}
.placeholder {
  border: 1px dashed $dark-grey;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.mealsContainerlayout {
  box-shadow: 0px 0px 30px rgb(190 204 223 / 30%);
  margin: 50px;
}
