.paymentcontainer {
  //margin
  margin-left: 165px;
  margin-right: 175px;
  margin-top: 50px;
  margin-bottom: 50px;
  padding-left: 50px;
  padding-right: 50px;
  display: flex;
  &__left {
    margin: 20px;

    background-color: white;
    box-shadow: 0px 4px 4px rgba(181, 181, 181, 0.25);
    width: 60%;
    &__header {
      height: 30%;
    }
    &__footer {
      padding-top: 55px;
      padding-bottom: 50px;
      &__toptext {
        padding-left: 55px;
        font-family: "Arial";
        font-style: normal;
        font-weight: 700;
        font-size: 30px;
        line-height: 34px;
        /* identical to box height */

        color: #06ab8d;
      }
      &__middletext {
        padding-left: 55px;
        padding-top: 20px;
        width: 90%;

        font-family: "Arial";
        font-style: normal;
        font-weight: 400;
        font-size: 17px;
        line-height: 23px;

        color: #34465e;
        &__top {
          padding: 20px 0px 0px;
        }
        &__middle {
          padding: 20px 0px 0px;
        }
        &__bottomtext {
          padding-top: 20px;
        }
        &__bottomemail {
          padding: 0px 0px 35px 0px;
        }
      }
    }
    &__box {
      &__container {
        width: 100%;
        height: 11rem;
        padding: 20px;
        background: #f2f2f1;
        display: flex;

        &__left {
          padding-left: 20px;

          &__top {
            font-family: "Arial";
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 26px;
            /* or 144% */

            text-transform: capitalize;

            color: #000000;
          }
          &__bottom {
            display: flex;
            gap: 5px;
          }
        }
        &__image {
          padding-top: 10px;
        }
      }
    }
  }
  &__right {
    width: 40%;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 19px;

    &__top {
      height: 29.5%;
      background-color: white;
      padding: 50px;
      box-shadow: 0px 4px 4px rgba(181, 181, 181, 0.25);
      width: 100%;
      &__text {
        font-family: "Arial";
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 28px;
        padding-bottom: 20px;
        color: #000000;
      }
      &__amount {
        font-family: "Arial";
        font-style: normal;
        font-weight: 700;
        font-size: 33.6207px;
        line-height: 39px;
        /* identical to box height */

        letter-spacing: 0.01em;
        padding-bottom: 20px;
        /* Green */

        color: #06ab8d;
      }
      &__class {
        font-family: "Arial";
        font-style: normal;
        font-weight: 700;
        font-size: 18.1034px;
        line-height: 21px;
        /* identical to box height */

        letter-spacing: 0.01em;

        /* Dark Grey */
        padding-bottom: 20px;
        color: #34465e;
      }
      &__duration {
        font-family: "Arial";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: 0.01em;

        color: #34465d;
      }
    }
    &__bottom {
      height: 66%;
      background-color: white;
      box-shadow: 0px 4px 4px rgba(181, 181, 181, 0.25);
      padding-top: 35px;
      width: 100%;
      &__heading {
        font-family: "Arial";
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 28px;

        padding-left: 30px;
        color: #000000;
      }
      &__amount {
        display: flex;
        justify-content: space-between;
        font-family: "Arial";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 149%;

        /* or 24px */
        padding: 40px 30px 18px;
        color: #34465e;
        border-bottom: 1px solid rgba(194, 207, 224, 0.3);
      }
      &__invoice {
        display: flex;
        justify-content: space-between;
        font-family: "Arial";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 149%;
        /* or 24px */
        padding: 20px 30px 20px;

        color: #34465e;
        border-bottom: 1px solid rgba(194, 207, 224, 0.3);
      }
      &__payment {
        display: flex;
        justify-content: space-between;
        font-family: "Arial";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 149%;
        /* or 24px */
        padding: 15px 30px 15px;
        color: #34465e;
        border-bottom: 1px solid rgba(194, 207, 224, 0.3);
      }
      &__date {
        display: flex;
        justify-content: space-between;
        font-family: "Arial";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 149%;
        /* or 24px */
        padding: 15px 30px 15px;
        color: #34465e;
        border-bottom: 1px solid rgba(194, 207, 224, 0.3);
      }
    }
  }
}
.payimage {
  width: 100%;
  height: 100%;
}
.invoicebutton {
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 19px;
  letter-spacing: 0.00130037px;
  color: #109cf1;
}
.invoice {
  padding: 25px 53px 25px;
  border-bottom: 1px solid rgba(194, 207, 224, 0.3);
  display: flex;
  justify-content: center;
}
.underline {
  text-decoration: underline;
}
.needhelp {
  display: flex;
  flex-direction: column;
  padding: 15px 30px 15px;
  font-family: "Arial";
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 23px;

  color: #34465e;
  &__middle {
    display: flex;
    &__or {
      padding-left: 5px;
    }
  }
}

.needhelpContainer {
  display: flex;
  justify-content: space-between;
  padding-top: 10px;
}

/* Extra Small Devices, Phones */
@media only screen and (max-width: 768px) {
  .paymentcontainer {
    display: flex;
    margin: 0%;
    padding: 10px;
    flex-direction: column;
    &__left {
      width: 100%;
      margin: 0%;
    }
    &__right {
      width: 100%;
    }
  }
}

/* Small Devices, Tablets */
@media only screen and (min-width: 768px) and (max-width: 992px) {
  .paymentcontainer {
    display: flex;
    margin: 0%;
    padding: 50px;
    flex-direction: column;
    &__left {
      width: 100%;
      margin: 0%;
    }
    &__right {
      width: 100%;
    }
  }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .paymentcontainer {
    display: flex;
    margin: 0%;
    padding: 50px;
    flex-direction: column;
    &__left {
      width: 100%;
      margin: 0%;
    }
    &__right {
      width: 100%;
    }
  }
}
