@import "variables.scss";
body {
  font-family: $font-family;
  margin: 0;
  background: #f3f6fa;
}
a {
  text-decoration: none;
}
.container {
  background: #f3f6fa;
  height: 100%;
  padding-left: 320px;
}
