@import "../../../styles/variables.scss";
.Meals {
  border-radius: 10px;
  height: 237px;

  background: linear-gradient(
    180deg,
    #f2f9ff 0%,
    rgba(242, 249, 255, 0.18) 100%
  );

  display: flex;

  &__MealContainer {
    margin-left: 50px;
    margin-right: 60px;
    margin-top: -170px;
    margin-bottom: 40px;
    height: 475px;
    background: #ffffff;
    box-shadow: 0px 0px 30px rgba(190, 204, 223, 0.3);
    border-radius: 12px;
    &__header {
      // font-family: $semi-bold;
      display: flex;
      flex-direction: row;
      padding: 26px 29px 0px 36px;
      &__text {
        font-family: $semi-bold;
        // font-family: "Poppins";
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
        letter-spacing: 0.025em;
        margin-left: 18px;
        margin-top: 1px;
      }
    }
    hr {
      border-top: 0px solid;
    }

    &__content {
      padding: 24px 16px 0px 18px;
      display: flex;

      &__left {
        width: 67%;
        height: 343px;
        border: 1px dashed #c2cfe0;
        background: #f3f6fa;
        box-sizing: border-box;
        border-radius: 10px;
        margin-left: 1px;
        &__header {
          height: 100px;
          width: 100.4%;
          margin-left: -1px;
          margin-top: -1px;
          display: flex;
          border: 1px dashed #c2cfe0;
          background: #ffffff;
          border-top-left-radius: 10px;
          border-top-right-radius: 10px;
          &__text1 {
            height: 18px;
            width: 30%;
            margin-left: 20px;
            margin-top: 25px;
            margin-bottom: 57px;
            border-radius: none;
            font-family: "Poppins";
            font-size: 12px;
            font-weight: 500;
            line-height: 18px;
            letter-spacing: 0.01em;
            text-align: left;
            color: #707683;
            &__info1 {
              margin-left: 75px;
              margin-top: -17px;
            }
            &__count1 {
              height: 21px;
              width: 200%;
              margin-left: 1px;
              margin-top: 7px;
              border-radius: none;
              font-family: $semi-bold;
              font-size: 14px;
              font-weight: 600;
              line-height: 21px;
              letter-spacing: 0.01em;
              text-align: left;
              color: #34465e;
              display: flex;
            }
          }
          &__text2 {
            height: 18px;
            width: 40%;
            margin-left: 85px;
            margin-top: 23px;
            margin-bottom: 57px;
            border-radius: none;
            font-family: "Poppins";
            font-size: 12px;
            font-weight: 500;
            line-height: 18px;
            letter-spacing: 0.01em;
            text-align: left;
            color: #707683;
            &__info2 {
              margin-left: 92px;
              margin-top: -17px;
            }
            &__count2 {
              &__goal {
                input {
                  font-family: $semi-bold;
                  font-size: 14px;
                  font-weight: 600;
                  line-height: 21px;
                  letter-spacing: 0.01em;
                  display: inline-block;
                  text-align: center;
                  color: #34465e;
                  margin-right: 0 !important;
                  margin-top: 5px;

                  margin-top: 4px;
                  background-color: #ffffff;
                  height: 29px;
                  width: 50px;
                  text-align: center;
                  border-radius: 5px;
                  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) inset;
                  border: none;
                  outline: none;
                }
                p {
                  font-family: $semi-bold;
                  font-size: 14px;
                  font-weight: 600;
                  line-height: 21px;
                  letter-spacing: 0.01em;
                  display: inline-block;
                  text-align: center;
                  color: #34465e;
                  margin-left: 12px;
                  margin-top: 4px;
                }
                input::-webkit-input-placeholder {
                  color: #34465e;
                }
                input:focus::-webkit-input-placeholder {
                  color: transparent;
                }
              }
              &__minus1 {
                margin-left: 60px;
                margin-top: -34px;
              }
              &__plus1 {
                margin-left: 80px;
                margin-top: -23px;
              }
            }
          }
          &__text3 {
            height: 18px;
            width: 40%;
            margin-left: 1px;
            margin-top: 23px;
            margin-bottom: 57px;
            border-radius: none;
            font-family: "Poppins";
            font-size: 12px;
            font-weight: 500;
            line-height: 18px;
            letter-spacing: 0.01em;
            text-align: left;
            color: #707683;
            &__info3 {
              margin-left: 90px;
              margin-top: -17px;
            }
            &__count3 {
              &__lbs {
                input {
                  margin-left: 1px;
                  margin-top: 4px;
                  background-color: #ffffff;
                  height: 29px;
                  width: 50px;
                  text-align: center;
                  border-radius: 5px;
                  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) inset;
                  font-family: $semi-bold;
                  margin-right: 0 !important;
                  font-size: 14px;
                  font-weight: bold;
                  line-height: 21px;
                  letter-spacing: 0.01em;
                  display: inline-block;
                  text-align: center;
                  color: #34465e;
                  margin-left: 1px;
                  border: none;
                  outline: none;
                }
                p {
                  font-family: $semi-bold;
                  font-size: 14px;
                  font-weight: 600;
                  line-height: 21px;
                  letter-spacing: 0.01em;
                  display: inline-block;
                  text-align: center;
                  color: #34465e;
                  margin-left: 12px;
                  margin-top: 4px;
                }
                input::-webkit-input-placeholder {
                  color: #34465e;
                }
                input:focus::-webkit-input-placeholder {
                  color: transparent;
                }
              }
              &__minus2 {
                margin-left: 60px;
                margin-top: -34px;
              }
              &__plus2 {
                margin-left: 80px;
                margin-top: -23px;
              }
            }
          }
        }
        &__body {
          &__pal {
            height: 186px;
            width: 97px;
            margin-left: 16px;
            margin-top: 27px;
            border: 1px dashed #c2cfe0;
            background: #ffffff;
            border-radius: 10px;
            &__run {
              margin-left: 25px;
              margin-top: 38px;
            }
            &__text {
              font-family: "Poppins";
              font-size: 12px;
              font-weight: 500;
              line-height: 18px;
              letter-spacing: 0.01em;
              text-align: left;
              color: #707683;
              margin-left: 31px;
              margin-top: 15px;
              &__info4 {
                margin-left: 25px;
                margin-top: -17px;
              }
              &__count {
                height: 27px;
                width: 36px;

                border-radius: none;
                font-family: $semi-bold;
                font-size: 18px;
                font-weight: 600;
                line-height: 27px;
                letter-spacing: 0.01em;
                text-align: left;
                color: #34465e;
              }
            }
          }
          &__activity {
            margin-left: 130px;
            margin-top: -180px;
            width: 100%;
            //  display: flex;

            &__work {
              height: 18px;
              width: 40%;
              // margin-top: 10px;
              // margin-left: 1%;
              border-radius: none;
              font-family: $semi-bold;
              font-size: 12px;
              font-weight: 500;
              line-height: 18px;
              letter-spacing: 0.01em;
              text-align: left;
              color: #34465e;
              &__slider {
                margin-top: 7px;
                &__container {
                  input {
                    width: 170%;
                  }
                }
              }
              p {
                margin-top: 7px;
                height: 15px;
                width: 500px;
                left: 247px;
                top: 401px;
                border-radius: none;
                font-family: $semi-bold;
                font-size: 10px;
                font-weight: 400;
                line-height: 15px;
                letter-spacing: 0em;
                text-align: left;
                color: #707683;
              }
            }
            &__daily {
              height: 18px;
              width: 40%;
              margin-top: 80px;
              border-radius: none;
              font-family: $semi-bold;
              font-size: 12px;
              font-weight: 500 bold;
              line-height: 18px;
              letter-spacing: 0.01em;
              text-align: left;
              color: #34465e;
              &__slider {
                margin-top: 7px;
                &__container {
                  input {
                    width: 170%;
                  }
                }
              }
              p {
                margin-top: 7px;
                height: 15px;
                width: 350px;
                left: 247px;
                top: 401px;
                border-radius: nullpx;
                font-family: Poppins;
                font-size: 10px;
                font-weight: 400;
                line-height: 15px;
                letter-spacing: 0em;
                text-align: left;
                color: #707683;
              }
            }
          }
        }
      }
      &__right {
        padding: 26px 4px 50px 30px;
        margin-top: -1px;
        width: 30%;
        height: 343px;
        margin-left: 20px;
        border: 1px dashed #c2cfe0;
        background: rgba(246, 255, 250, 0.8);
        box-sizing: border-box;
        border-radius: 10px;
        cursor: "pointer";
        &__allergies {
          margin-left: -1px;
          margin-top: -5px;
          height: 45%;
          cursor: "pointer";
          &__header {
            font-family: $semi-bold;
            font-size: 14px;
            font-weight: 600;
            line-height: 21px;
            letter-spacing: 0.01em;
            text-align: left;
            border-radius: none;
            color: #34465e;
            padding: 10px 10px;
            display: flex;
            justify-content: space-between;
            margin-left: -19px;
            margin-top: -16px;
            cursor: "pointer";
          }
          &__body {
            font-family: "Poppins";
            font-size: 10px;
            font-weight: 500;
            line-height: 16px;
            letter-spacing: 0.02em;
            text-align: center;
            color: #707683;
            margin-top: 1px;
            display: flex;
            flex-wrap: wrap;
            overflow-y: auto;
            margin-left: -15px;
            max-height: 110px;
            width: 100%;
            height: 85%;
            cursor: "pointer";
            &__items {
              border: 1px solid #ebeff2;
              font-family: "Poppins-Medium";
              font-style: normal;
              font-weight: 500;
              font-size: 10px;
              padding: 5px;
              margin: 5px;
              margin-top: 2px;
              border-radius: 5px;
              letter-spacing: 0.02em;
              text-transform: capitalize;
              color: #707683;
              background-color: #ffffff;
              cursor: pointer;
            }
          }
        }
        &__preferences {
          margin-left: -7px;
          margin-top: -5px;
          height: 45%;
          cursor: "pointer";
          &__header {
            font-family: $semi-bold;
            font-size: 14px;
            font-weight: 600;
            line-height: 21px;
            letter-spacing: 0.01em;
            text-align: left;
            border-radius: none;
            color: #34465e;
            padding: 55px 9px;
            display: flex;
            justify-content: space-between;
            margin-left: -10px;
            cursor: "pointer";
          }
          &__body {
            font-family: "Poppins";
            font-size: 10px;
            font-weight: 500;
            line-height: 16px;
            letter-spacing: 0.02em;
            text-align: center;
            color: #707683;
            margin-top: -40px;
            display: flex;
            flex-wrap: wrap;
            overflow-y: auto;
            margin-left: -10px;
            max-height: 110px;
            width: 97%;
            height: 85%;
            cursor: "pointer";

            &__items {
              border: 1px solid #ebeff2;
              font-family: "Poppins-Medium";
              font-style: normal;
              font-weight: 500;
              font-size: 10px;
              padding: 5px;
              margin: 5px;
              margin-top: 2px;
              border-radius: 5px;
              letter-spacing: 0.02em;
              text-transform: capitalize;
              color: #707683;
              background-color: #ffffff;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}
.Meals__MealContainer__content__right__allergies__body::-webkit-scrollbar {
  width: 5px;
  padding: auto;
}

.Meals__MealContainer__content__right__allergies__body::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
}

.Meals__MealContainer__content__right__allergies__body::-webkit-scrollbar-thumb {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  border-radius: 5px;
}
.Meals__MealContainer__content__right__preferences__body::-webkit-scrollbar {
  width: 5px;
}

.Meals__MealContainer__content__right__preferences__body::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
}

.Meals__MealContainer__content__right__preferences__body::-webkit-scrollbar-thumb {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  border-radius: 5px;
}

.Meals__MealContainer__content__right__allergies__body__items__hide {
  display: none;
}
.Meals__MealContainer__content__right__allergies__body__items:hover
  + .Meals__MealContainer__content__right__allergies__body__items__hide {
  display: flex;
  justify-content: flex-end;
  margin-top: -2.2rem;
  cursor: "pointer";
}

.Meals__MealContainer__content__right__preferences__body__items__hide {
  display: none;
}
.Meals__MealContainer__content__right__preferences__body__items:hover
  + .Meals__MealContainer__content__right__preferences__body__items__hide {
  display: flex;
  justify-content: flex-end;
  margin-top: -2.2rem;
  cursor: "pointer";
}
