.Editmodal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
}
.contentbox {
  width: 50px;
}
.HeaderText {
  padding-bottom: 20px;
  padding-top: 20px;
  padding-left: 30px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.025em;
}
.Editmodal-main {
  position: fixed;
  background: white;
  width: 395px;
  height: 295px;
  top: 50%;
  left: 50%;
  box-shadow: 0px 0px 50px rgba(255, 255, 255, 0.3);
  border-radius: 10px;
  transform: translate(-50%, -50%);
}
.calories {
  padding-left: 30px;
  padding-bottom: 24px;
}
.modalcontent {
  display: flex;
  padding: 21px 0px 24px 30px;
}
.heading {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  /* identical to box height */
  padding-left: 30px;
  padding-top: 10px;
  padding-right: 10px;
  /* Title */

  color: #202c46;
  &__image {
    cursor: pointer;
    float: right;
    margin-right: 10px;
    margin-top: 5px;
  }
}

.display-block {
  z-index: 1;
  display: block;
}

.display-none {
  display: none;
}

.addpercent {
  cursor: pointer;

  float: right;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  margin-right: 55px;
  color: #ffff;
  width: 14%;
  height: 9%;
  background-color: rgb(16, 156, 241);
  border: 1px solid rgb(16, 156, 241);
  border-radius: 5px;
}
.inputType {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.025em;
}

.contentalign {
  display: flex;
}
.contentbox {
  border: 1px solid #c2cfe0;
  box-sizing: border-box;
  margin-left: 5px;
  height: 25px !important;
  margin-right: 5px !important;
}
.contentboxone {
  border: 1px solid #c2cfe0;
  box-sizing: border-box;
  margin-left: 5px;
  height: 25px !important;
  margin-right: 5px !important;
  width: 100px !important;
}
