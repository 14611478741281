@import "../../styles/variables.scss";
.tab-list {
  padding: 5px 30px;
  margin: 0;

  font-family: $font-family;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 28px;
}

.tab-list-item {
  display: inline-block;
  list-style: none;
  margin-bottom: -1px;
  margin: 0px 10px 0;
  color: #34465e;
  cursor: pointer;
}

.tab-list-active {
  color: #109cf1;
  border-bottom: 2px solid;
}
.tab-content {
  padding: 10px 40px;
}

.createNewWorkout{
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 28px;
  align-items: center;
  color: #06AB8D;
  /* outline: none; */
  border: none;
  background: rgba(232, 244, 254);
  float: right;
}

.addButton{
  margin-right: 2px;
}
