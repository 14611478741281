.Editmodal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
}
.contentbox {
  width: 50px;
}
.durationtext {
  padding-bottom: 20px;
  padding-top: 6px;
  padding-left: 125px;
  display: flex;
  float: inline-start;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.025em;
  color: #34465e;
}
.Editmodal-meal {
  position: fixed;
  background: white;
  width: 500px;
  height: 350px;
  top: 50%;
  left: 50%;
  box-shadow: 0px 0px 50px rgba(255, 255, 255, 0.3);
  border-radius: 10px;
  transform: translate(-50%, -50%);
}
.MealPlan {
  padding-top: 10px;
  padding-left: 30px;
  display: flex;
}
.loadheading {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  padding-left: 30px;
  padding-top: 10px;
  padding-right: 10px;
  padding-bottom: 40px;
  color: #202c46;
  &__image {
    cursor: pointer;
    float: right;
    margin-right: 10px;
    margin-top: 5px;
  }
}

.display-block {
  z-index: 4 !important;
  display: block;
}

.display-none {
  display: none;
}

.Load {
  cursor: pointer;

  float: right;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  margin-right: 55px;
  color: #ffff;
  width: 20%;
  height: 30px;
  background-color: rgb(16, 156, 241);
  border: 1px solid rgb(16, 156, 241);
  border-radius: 5px;
}
.inputMealPlan {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.025em;
  color: #34465e;
}

.error {
  color: red;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 200;
  font-size: 12px;
  line-height: 22px;
  letter-spacing: 0.025em;
  margin-left: 113px;
}
