@import "../../styles/variables.scss";
.backtoleads {
  content: "\003C";
  background: #ffffff;
  align-items: center;
  border: none;
  margin-left: 10px;
  font-family: $font-medium;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.00140039px;
  color: #1c78ba;
}
.backtoclients:hover {
  cursor: pointer;
}
.bolder {
  font-family: $semi-bold;
}
.filterbtn {
  font-family: $font-family;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 19px;
  letter-spacing: 0.00130037px;
  color: #109cf1;
}
.createnewbtn {
  font-family: $font-family;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 19px;
  letter-spacing: 0.00130037px;
  color: #ffffff;
}

.leaddetails {
  &__header {
    display: flex;
    height: 118px;
    padding: 0px 25px;
    margin-left: 11px;
    align-items: center;
    &__text {
      flex: 1;
      color: #000000;
      font-size: 26px;
      font-weight: 500;
      font-family: $font-medium;
      font-style: normal;
      line-height: 39px;
    }
  }

  &__container {
    padding-right: 28px;
    padding-left: 25px;
    margin-left: 10px;
    margin-top: 39px;

    &__content {
      float: left;
      width: 70%;
      padding: 10px;

      box-shadow: 6px 0px 18px rgb(0 0 0 / 6%);
      margin-top: -2em;
      background: linear-gradient(
        180deg,
        #f2f9ff 0%,
        rgba(242, 249, 255, 0.18) 100%
      );
      border: 1px solid rgba(194, 207, 224, 0.3);
      box-sizing: border-box;
      border-radius: 8px 0px 0px 0px;

      &__header {
        &__profile {
          display: flex;
          margin-left: 15px;
          margin-right: 15px;
          padding-left: 10px;
          padding-top: 31px;
          padding-right: 10px;
          justify-content: space-between;

          &__name {
            display: flex;
            &__username {
              font-size: 18px;
              font-family: $semi-bold;
              font-weight: 600;
              display: flex;
              flex-direction: column;
              margin-bottom: auto;
              margin-left: 10px;
            }
            &__age {
              font-family: $font-medium;
              font-weight: 500;
              font-size: 13px;
              letter-spacing: 0.01em;
              color: #707683;
            }
            &__image {
              margin-top: 5px;
            }
            &__health {
              display: flex;
              &__goal {
                display: flex;
                flex-direction: column;
                padding-left: 1px;
                padding-right: 1px;
                letter-spacing: 0.01em;
                color: #707683;
                font-family: $font-medium;
                font-size: 12px;
                font-weight: 500;
                &__color {
                  font-family: $font-medium;
                  font-style: normal;
                  font-weight: 500;
                  font-size: 16px;
                  line-height: 24px;
                  /* identical to box height */

                  text-align: right;
                  letter-spacing: 0.01em;

                  /* Green */
                  color: #06ab8d;
                }
              }
              &__image {
                padding-bottom: 10px;
              }
            }
          }
        }
      }
      &__contact {
        // font-size: medium;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: #ffffff;
        margin-left: 15px;
        margin-right: 15px;
        padding: 10px;
        // line-height: 0.5px;
        height: 80px;
        box-shadow: 6px 0px 18px rgb(0 0 0 / 6%);

        &__email {
          margin-left: 20px;
          display: flex;
          &__filter {
            margin-top: 3px;

            &__text {
              margin-left: 0.3em;
              margin-top: 5px;
              opacity: 0.5;
              font-size: 12px;
              &__email {
                font-family: $font-medium;
                color: $dark-grey;
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                margin-left: 20px;
              }
            }
          }
        }
        &__phoneno {
          margin-right: 50px;
          display: flex;

          &__filter {
            margin-top: 3px;

            &__text {
              margin-left: 0.4em;
              margin-top: 5px;
              opacity: 0.5;
              font-size: 12px;
              &__phoneno {
                font-family: $font-medium;
                color: $dark-grey;
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                margin-right: 50px;
                display: flex;
                justify-content: flex-start;
              }
            }
          }
        }
      }
      &__chat {
        background-color: #ffffff;
        margin-left: 15px;
        margin-right: 15px;
        margin-top: 15px;
        padding: 12px;
        box-shadow: 6px 0px 18px rgb(0 0 0 / 6%);
      }
    }

    &__sidebar {
      float: left;
      display: flex;
      flex-flow: column;
      justify-content: space-between;
      width: 30%;
      height: 905px;
      margin-top: -1.9em;

      padding: 10px;
      background-color: #fff;
      box-shadow: 6px 0px 18px rgb(0 0 0 / 6%);

      &__list {
        flex-basis: 40%;
        border-bottom: 1px solid rgba(194, 207, 224, 0.3);
        overflow: scroll;
        h2 {
          font-family: $semi-bold;
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 24px;
          letter-spacing: 0.01em;
          color: $dark-grey;
          margin-left: 30px;
          color: #34465e;
        }
        &__scroll {
          height: 32%;
          // border-bottom: 1px solid rgba(194, 207, 224, 0.3);
          p {
            font-family: $font-family;
            font-style: normal;
            font-weight: 400;
            font-size: 13px;
            line-height: 21px;
            letter-spacing: 0.01em;
            width: 236px;

            margin-left: 30px;
            color: #334d6e;
            display: flex;
            flex-wrap: wrap;
          }
        }
        hr {
          background: rgba(194, 207, 224, 0.3);
          opacity: 30%;
          margin-top: 60px;
        }
      }
      &__status {
        flex-basis: 17%;

        border-bottom: 1px solid rgba(194, 207, 224, 0.3);
        h2 {
          font-family: $semi-bold;
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 24px;
          letter-spacing: 0.01em;
          color: #34465e;
          margin-left: 30px;
        }
        hr {
          background: rgba(194, 207, 224, 0.3);
          opacity: 30%;
          margin-top: 60px;
        }
        .statusbtn {
          background: #ffffff;
          border: 1px solid #c2cfe0;
          border-radius: 5px;
          width: 80%;
          margin-left: 30px;
          height: 38px;
          font-family: $font-family;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 21px;
          letter-spacing: 0.00130037px;
          color: $dark-grey;
          margin-bottom: 30px;
        }
      }
      &__notes {
        h2 {
          display: flex;
          font-family: $semi-bold;
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 24px;
          letter-spacing: 0.01em;
          color: #34465e;
          margin-left: 30px;
        }
        &__container {
          &__textArea {
            width: 80%;
            font-family: $font-family;
            font-style: normal;
            font-weight: normal;
            font-size: 13px;
            height: 200px;
            letter-spacing: 0.01em;
            color: #707683;
            padding-top: 33px;
            margin-left: 30px;
            padding: 10px;
            resize: none;
            border: 1px solid #c2cfe0;
            box-sizing: border-box;
            box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
          }
          &__notes {
            width: 80%;
            overflow: auto;
            font-family: $font-family;
            font-style: normal;
            font-weight: normal;
            font-size: 13px;
            height: 200px;
            letter-spacing: 0.01em;
            color: #707683;
            margin-left: 30px;
            border: 1px solid #c2cfe0;
            box-sizing: border-box;
            box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
            flex-direction: column;
            gap: 10px;
            padding: 0px 10px 0px 15px;
            &__item {
              box-sizing: border-box;
              box-shadow: 0px 6px 18px rgb(0 0 0 / 20%);
              border: 1px solid #ebeff2;
              font-family: "Poppins-Medium";
              font-style: normal;
              font-weight: 500;
              font-size: 10px;
              padding: 5px;
              margin: 5px;
              margin-top: 2px;
              border-radius: 5px;
              letter-spacing: 0.02em;
              text-transform: capitalize;
              color: #707683;
              background-color: #ffffff;
              cursor: pointer;
            }
            &__sub {
              width: 110%;
              &__header {
                display: flex;
                justify-content: space-between;
                padding: 10px 10px;
                color: $blue;
                font-style: normal;

                &__closed {
                  display: flex;
                  justify-content: flex-end;
                  padding-right: 15px;
                  cursor: pointer;
                }
              }
            }
          }
          .text_button {
            width: 77.5%;
            font-family: $font-medium;
            font-style: normal;
            font-weight: 600;
            font-size: 10px;
            line-height: 21px;
            letter-spacing: 0.01em;
            text-align: justify;
            padding: 4px;
            color: $blue;
            background: #ffffff;
            padding-left: 3%;
            float: right;
            border-right: none;
            border: 1px solid #c2cfe0;
            box-sizing: border-box;
            box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
            border-top: none;
            border-bottom-left-radius: 5px;
            cursor: pointer;
            &__plus {
              border: 1px solid #c2cfe0;
              padding-top: 0%;
              border-top: none;
              border-left: none;
              border-bottom-right-radius: 5px;
              background-color: #ffffff;
              cursor: pointer;
            }
          }
        }
        .notestypebuttons {
          margin-left: 30px;
          display: flex;
        }
      }
      &__notes {
        flex-basis: 30%;
      }
    }
  }
}
.dummyUI {
  filter: blur(2px);
}

.leaddetails__container__sidebar__list__scroll::-webkit-scrollbar {
  width: 4px;
  padding: auto;
}

.leaddetails__container__sidebar__list__scroll::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 4px;
}

.leaddetails__container__sidebar__list__scroll::-webkit-scrollbar-thumb {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  border-radius: 4px;
}
